import { t } from 'i18next';
import { ReactNode } from 'react';
import lowercase from 'lodash/lowerCase';
import { BillingModel, Currency } from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { getPriceAndPeriodFormat } from '../../../../../packages/pricing/utils/priceFormatUtils';
import { PriceBreakdown } from './priceBreakdown';
import { currencyPriceFormatter } from '../../../../../packages/pricing/components/currency/currencyUtils';

type TotalPriceProps = {
  priceBreakdown: PriceBreakdown | null;
  billingCurrency?: Currency;
  withCodePostfix?: boolean;
};

type UseTotalPriceProps = TotalPriceProps & { withoutDiscount?: boolean };

type TotalPrice = {
  minimumSpend: ReactNode | undefined;
  totalValue: number | undefined;
  total: ReactNode;
  totalDiscount: string | null;
  startingAtPrefix: string | undefined;
  billingPeriod: JSX.Element | null;
  oneOffTotal: string;
  oneOffTotalDiscount: string | null;
  currency: Currency | undefined;
};

export function useTotalPrice({
  priceBreakdown,
  withoutDiscount,
  billingCurrency,
  withCodePostfix,
}: UseTotalPriceProps): TotalPrice {
  let totalString: string;
  const {
    hasUsageBasedPrice,
    hasPayAsYouGoPrice,
    withStartsAt,
    total,
    subtotal,
    planBreakdown,
    currency,
    oneOffSubtotal,
    oneOffTotal,
    minimumSpend,
  } = priceBreakdown || {};

  const isSingleUsagePrice = (hasUsageBasedPrice || hasPayAsYouGoPrice) && planBreakdown?.prices?.length === 1;
  const totalValue = withoutDiscount ? subtotal : total;
  const pricesValue = withoutDiscount
    ? planBreakdown?.prices
    : planBreakdown?.pricesAfterDiscount ?? planBreakdown?.prices;

  const priceCurrency = currency || billingCurrency;
  const startingAtPrefix = withStartsAt ? t('priceBreakdown.startsAt') : undefined;

  const oneOffTotalString = oneOffTotal
    ? `${currencyPriceFormatter({ amount: oneOffTotal, currency: priceCurrency, options: { withCodePostfix } })}`
    : '';
  const oneOffTotalDiscount =
    !isNil(oneOffSubtotal) && !isNil(oneOffTotal) && oneOffSubtotal > oneOffTotal
      ? currencyPriceFormatter({
          amount: oneOffTotal - oneOffSubtotal,
          currency: priceCurrency,
          options: { withCodePostfix },
        })
      : null;

  if (!priceBreakdown || totalValue === 0) {
    totalString = currencyPriceFormatter({ amount: 0, currency: priceCurrency, options: { withCodePostfix } });
  } else {
    totalString = totalValue
      ? `${currencyPriceFormatter({ amount: totalValue, currency: priceCurrency, options: { withCodePostfix } })}`
      : '';
  }

  let totalElement: ReactNode;

  if (withStartsAt) {
    totalElement = `${startingAtPrefix} ${totalString}`;
  } else {
    totalElement = (
      <>
        {isSingleUsagePrice
          ? `${totalValue ? `${totalString}` : ''}${pricesValue
              ?.filter(({ price }) => price.billingModel === BillingModel.UsageBased)
              ?.map(({ price }, index) => {
                return `${index > 0 || (index === 0 && totalValue) ? ' + ' : ''}${getPriceAndPeriodFormat(price, {
                  shortFormat: !withCodePostfix,
                  totalPrice: true,
                })}`;
              })}`
          : totalString}
      </>
    );
  }

  const minimumSpendNode = minimumSpend ? currencyPriceFormatter({ amount: minimumSpend }) : undefined;
  const totalDiscount =
    !isNil(subtotal) && !isNil(totalValue) && subtotal > totalValue
      ? currencyPriceFormatter({ amount: totalValue - subtotal, currency: priceCurrency, options: { withCodePostfix } })
      : null;

  const billingPeriod = priceBreakdown?.billingPeriod ? (
    <>({lowercase(t(`pricing.billedPeriod.${priceBreakdown?.billingPeriod}`))})</>
  ) : null;

  return {
    total: totalElement,
    totalValue,
    totalDiscount,
    minimumSpend: minimumSpendNode,
    billingPeriod,
    currency: priceCurrency,
    startingAtPrefix,
    oneOffTotal: oneOffTotalString,
    oneOffTotalDiscount,
  };
}
