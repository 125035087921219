import { TextColor } from '@stigg-theme';
import { Text, GridFlex, LongText } from '@stigg-components';

export function TwoLinesLayout({
  firstRow,
  secondRow,
  withStroke,
  secondLineColor,
}: {
  firstRow?: string;
  secondRow?: string;
  withStroke?: boolean;
  secondLineColor?: TextColor;
}) {
  return (
    <GridFlex.Column $fullWidth sx={{ textDecoration: withStroke ? 'line-through' : 'none' }}>
      {firstRow && <Text.B2>{firstRow}</Text.B2>}
      {secondRow && <LongText color={secondLineColor}>{secondRow}</LongText>}
    </GridFlex.Column>
  );
}
