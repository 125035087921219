import { t } from 'i18next';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import sortBy from 'lodash/sortBy';
import { Alert, Divider, GridFlex, Icon, Text } from '@stigg-components';
import { formatDate } from '@stigg-common';
import {
  InvoiceLineItemType,
  PreviewNextInvoiceFragment,
  SubscriptionDataFragment,
  SubscriptionStatus,
  TrialEndBehavior,
} from '@stigg-types/apiTypes';
import {
  AmountDueSection,
  ChargesBreakdown,
  ChargesList,
  CreditsSection,
  DiscountsSection,
  SubTotalSection,
  TaxesSection,
  TotalSection,
} from '.';

type InvoiceBreakdownProps = {
  invoice: PreviewNextInvoiceFragment;
  subscription?: SubscriptionDataFragment;
};

export function InvoiceBreakdown({ invoice, subscription }: InvoiceBreakdownProps) {
  const { lines, total, amountDue, credits, discount, discountDetails, tax, taxDetails } = invoice;

  const linesByPeriod = groupBy(lines, ({ period }) => {
    const withYear = new Date(period?.start)?.getFullYear() !== new Date(period?.end)?.getFullYear();
    return `${formatDate(period?.start, { formatType: 'shortDate', withYear })} - ${formatDate(period?.end, {
      formatType: 'shortDate',
      withYear: true,
    })}`;
  });
  const sortedPeriods = sortBy(toPairs(linesByPeriod), ([_, lines]) => lines?.[0].period?.start);

  const isTrialConvertToPaid =
    subscription?.status === SubscriptionStatus.InTrial &&
    subscription.trialConfiguration?.trialEndBehavior === TrialEndBehavior.ConvertToPaid;
  const alertText = isTrialConvertToPaid
    ? t('subscriptions.previewNextBill.trialConvertToPaidAlert')
    : t('subscriptions.previewNextBill.breakdownAlert');
  const alertIcon = isTrialConvertToPaid ? (
    <Icon icon="HourglassEmpty" type="custom" color="default" overrideStroke />
  ) : (
    <Icon icon="InfoOutlined" color="default" />
  );

  return (
    <GridFlex.Column container>
      <GridFlex.Column container gap={6} marginTop={4}>
        {map(sortedPeriods, ([dateRange, periodLines]) => {
          const minSpendItems =
            periodLines?.filter((line) => line.type === InvoiceLineItemType.MinimumSpendAdjustmentCharge) || [];

          return (
            <GridFlex.Column container>
              <Text.B2 $bold mb={4}>
                {dateRange}
              </Text.B2>
              <ChargesBreakdown lines={periodLines} plan={subscription?.plan} />

              <ChargesList items={minSpendItems} marginTop={4} />
            </GridFlex.Column>
          );
        })}
      </GridFlex.Column>

      <Divider my={4} />

      <SubTotalSection subTotal={invoice.subTotal} />

      <DiscountsSection discount={discount} discountDetails={discountDetails} />

      <Divider mt={4} />

      <TaxesSection tax={tax} taxDetails={taxDetails} />

      <TotalSection total={total} />

      <CreditsSection credits={credits} />

      <AmountDueSection amountDue={amountDue} />

      <Alert severity="info" mt={4} mb={8} icon={alertIcon}>
        <Text.B2 color="primary">{alertText}</Text.B2>
      </Alert>
    </GridFlex.Column>
  );
}
