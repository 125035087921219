import { t } from 'i18next';
import { Grid, Link, Text } from '@stigg-components';
import { getSubscriptionCurrency } from '@stigg-common';
import { CouponChangeVariables, CouponFragment, SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { useNavigation } from '../../../../../../../../navigation/useNavigation';
import { ChangeType, SubscriptionFutureUpdateRowContainer } from '../../SubscriptionFutureUpdateRowContainer';
import { formatCoupon } from '../../../../../../../../coupons/components/utils';

type AddonQuantityUpdatedRowProps = {
  coupon: CouponFragment;
  changeVariables: CouponChangeVariables;
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
};

export function CouponAddedRow({
  coupon,
  subscription,
  changeVariables: _,
  alertVariant,
}: AddonQuantityUpdatedRowProps) {
  const navigation = useNavigation();
  const currency = getSubscriptionCurrency(subscription);

  return (
    <SubscriptionFutureUpdateRowContainer changeType={ChangeType.ADDED}>
      <Grid alignItems="baseline" gap={1}>
        <Text.B2 color={alertVariant ? 'warning.content' : 'primary'}>
          {t('subscriptions.schedules.couponAddPrefix')}
        </Text.B2>
        <Link
          variant="body2"
          onClick={() => {
            navigation.navigateTo(navigation.appRoutes.couponPage(coupon.refId));
          }}>
          <Text.B2 $bold color={alertVariant ? 'warning.content' : undefined}>
            {formatCoupon(coupon, currency, { showNegativeSign: false, withCodePostfix: false })}
          </Text.B2>
        </Link>
        <Text.B2 color={alertVariant ? 'warning.content' : 'primary'}>{t('subscriptions.schedules.coupon')}</Text.B2>
      </Grid>
    </SubscriptionFutureUpdateRowContainer>
  );
}
