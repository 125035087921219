import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CouponChangeVariables, CouponFragment, SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { CouponAddedRow } from './coupon/CouponAddedRow';
import { RootState, useAppDispatch } from '../../../../../../../../redux/store';
import { fetchCouponByRefIdAction } from '../../../../../../../coupons/couponsSlice';

export function CouponUpdateRow({
  changeVariables,
  subscription,
  alertVariant,
}: {
  changeVariables: CouponChangeVariables;
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [coupon, setCoupon] = useState<CouponFragment | null>(null);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const { couponId } = changeVariables;

  useEffect(() => {
    const fetchCoupon = async () => {
      const coupon = await dispatch(
        fetchCouponByRefIdAction({ refId: couponId, environmentId: currentEnvironmentId || '' }),
      ).unwrap();
      setCoupon(coupon);
    };

    void fetchCoupon();
  }, [dispatch, currentEnvironmentId, couponId]);

  if (!coupon) {
    return null;
  }

  return (
    <CouponAddedRow
      subscription={subscription}
      coupon={coupon}
      changeVariables={changeVariables}
      alertVariant={alertVariant}
    />
  );
}
