import React, { useMemo } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const AnalyticsContext = React.createContext<AnalyticsBrowser | null>(null);

interface AnalyticsProviderProps {
  writeKey: string;
  children: React.ReactNode;
}
export const AnalyticsProvider = ({ writeKey, children }: AnalyticsProviderProps) => {
  const analyticsBrowser = useMemo(
    () =>
      AnalyticsBrowser.load(
        { writeKey },
        {
          integrations: {
            'Segment.io': {
              deliveryStrategy: {
                config: {
                  keepalive: true, // see https://github.com/segmentio/analytics-next/issues/768
                },
              },
            },
          },
        },
      ),
    [writeKey],
  );
  return <AnalyticsContext.Provider value={analyticsBrowser}>{children}</AnalyticsContext.Provider>;
};
