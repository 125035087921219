import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { AccountStatus, User } from '@stigg-types/apiTypes';
import merge from 'lodash/merge';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState } from '../../redux/store';
import { AnalyticsContext } from './AnalyticsProvider';
import { useCurrentMember } from '../auth/hooks/useCurrentMember';

type Analytics = {
  track: (eventName: SegmentEvents | string, obj?: any) => void;
  identify: (username: string, obj?: any) => void;
  alias: (username: string) => void;
  page: () => void;
  group: (groupId: string, obj?: any) => void;
};

export enum SegmentEvents {
  ButtonClicked = 'Button Clicked',
}

const useAnalyticsContext = () => {
  const analytics = useContext(AnalyticsContext);
  if (!analytics) {
    throw new Error(
      'Analytics context was not initialized, AnalyticsProvider is probably missing from the component tree',
    );
  }

  return analytics;
};

export function useAnalytics(): Analytics {
  const theme = useTheme();
  const analytics = useAnalyticsContext();
  const user: User | null = useSelector((state: RootState) => state.authReducer.user);
  const { setAccountToBlockedOnCreation: accountShouldBeBlocked } = useFlags<FeatureFlags>();
  const member = useCurrentMember();

  return useMemo(() => {
    let identityContext = {};
    let identityContextWithIsBlocked = {};
    if (user) {
      identityContext = {
        email: user.email || '',
        name: user.name || '',
        id: user.id || '',
        selected_theme: theme.themeVariant || '',
        ...(member?.account
          ? {
              account: member.account.displayName || '',
              accountId: member.account.id || '',
            }
          : {}),
      };

      identityContextWithIsBlocked = {
        ...identityContext,
        ...(member?.account
          ? { isBlocked: member.account.accountStatus === AccountStatus.Blocked || accountShouldBeBlocked }
          : {}),
      };
    }
    const track = (eventName: SegmentEvents | string, obj?: any) => {
      if (analytics) {
        void analytics.track(eventName, merge(obj, identityContextWithIsBlocked));
      }
    };
    const identify = (username: string, obj?: any) => {
      if (analytics) {
        void analytics.identify(username, merge(obj, identityContext));
      }
    };

    const alias = (username: string) => {
      if (analytics) {
        void analytics.alias(username);
      }
    };

    const page = () => {
      if (analytics) {
        void analytics.page({ properties: identityContextWithIsBlocked });
      }
    };
    // this is used only to group members on same account/company
    const group = (accountId: string, obj?: any) => {
      if (analytics) {
        void analytics.group(accountId, merge({}, identityContextWithIsBlocked, obj));
      }
    };
    return {
      track,
      identify,
      page,
      group,
      alias,
    };
  }, [user, member, accountShouldBeBlocked, analytics, theme.themeVariant]);
}
