import { t } from 'i18next';
import { Field, FormRenderProps } from '@stigg-components';
import { AdditionalInputLayout } from '.';
import { SubscriptionFormFields } from '../SubscriptionForm.types';

type SubscriptionStartProps = {
  isUpdatingSubscription: boolean;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
};
export function SubscriptionStart({ isUpdatingSubscription, formRenderProps }: SubscriptionStartProps) {
  const formFields: Field<SubscriptionFormFields>[] = [
    {
      type: 'datePicker',
      id: 'startDate',
      disabled: isUpdatingSubscription,
      gridProps: {
        width: 260,
      },
    },
  ];

  return (
    <AdditionalInputLayout
      isSet
      title={t('subscriptionForm.subscriptionScheduleSectionTitle')}
      setIcon={{ icon: 'Calendar' }}
      setLabel={t('subscriptions.createSubscriptionStartDateField')}
      fields={formFields}
      formRenderProps={formRenderProps}
    />
  );
}
