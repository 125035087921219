import { t } from 'i18next';
import { CustomDrawer } from '@stigg-components';
import CreateCouponForm from './CreateCouponForm';

type CreateCouponDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function CreateCouponDrawer({ open, setOpen }: CreateCouponDrawerProps) {
  return (
    <CustomDrawer
      title={t('coupons.createDialogTitle')}
      isOpen={open}
      onClose={() => setOpen(false)}
      variant="persistent">
      <CreateCouponForm key={`${open}`} onCancel={() => setOpen(false)} />
    </CustomDrawer>
  );
}
