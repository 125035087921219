import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { formatDate } from '@stigg-common';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import { Button, Icon, Text } from '@stigg-components';
import {
  SubscriptionDataFragment,
  SubscriptionFutureUpdateDataFragment,
  SubscriptionScheduleStatus,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { SubscriptionFutureUpdateRow } from './SubscriptionFutureUpdateRow';
import { SubscriptionBannerContainer } from '../SubscriptionBannerContainer';

const getScheduledUpdatesExecutionData = (
  futureUpdates: SubscriptionFutureUpdateDataFragment[],
  currentBillingPeriodEnd: Date,
) => {
  const scheduledExecutionTimeNotInNextBillingCycle = futureUpdates.find(
    ({ scheduledExecutionTime }) => !moment(scheduledExecutionTime).isSame(currentBillingPeriodEnd, 'day'),
  )?.scheduledExecutionTime;

  const formattedScheduledExecutionTime = scheduledExecutionTimeNotInNextBillingCycle
    ? formatDate(scheduledExecutionTimeNotInNextBillingCycle)
    : null;

  const scheduleUpdateTitleKey = scheduledExecutionTimeNotInNextBillingCycle
    ? 'subscriptions.schedules.updatesScheduledTo'
    : 'subscriptions.schedules.updatesScheduledToNextBillingCycle';

  return { formattedScheduledExecutionTime, scheduleUpdateTitleKey };
};

type SubscriptionFutureUpdatesProps = {
  subscription: SubscriptionDataFragment;
  onCancelSubscriptionFutureUpdatesClick: (futureUpdateStatus: ActiveFutureUpdateStatus) => void;
};

export function SubscriptionScheduledUpdatesBanner({
  subscription,
  onCancelSubscriptionFutureUpdatesClick,
}: SubscriptionFutureUpdatesProps) {
  const theme = useTheme();
  const scheduledUpdates = subscription.futureUpdates.filter(
    ({ scheduleStatus }) => scheduleStatus === SubscriptionScheduleStatus.Scheduled,
  );

  if (!scheduledUpdates?.length) {
    return null;
  }

  const { scheduleUpdateTitleKey, formattedScheduledExecutionTime } = getScheduledUpdatesExecutionData(
    scheduledUpdates,
    subscription.currentBillingPeriodEnd,
  );

  const cancelUpdatesButton = (
    <Button
      $outlined
      textColor={theme.isLightTheme ? undefined : 'primary'}
      color={theme.isLightTheme ? 'outlinedRestingBackground' : 'outlineBorder'}
      onClick={() => onCancelSubscriptionFutureUpdatesClick(SubscriptionScheduleStatus.Scheduled)}
      startIcon={
        <Icon icon="Trash2" type="reactFeather" size={20} color={theme.isLightTheme ? 'default' : 'primary.main'} />
      }>
      <Text.B2>{t('subscriptions.schedules.cancelUpdate')}</Text.B2>
    </Button>
  );

  return (
    <SubscriptionBannerContainer
      title="Update scheduled"
      icon={<Icon icon="Schedualed" type="custom" color="default" overrideFill />}
      buttons={cancelUpdatesButton}>
      <Text.B2>{t(scheduleUpdateTitleKey, { date: formattedScheduledExecutionTime })}</Text.B2>
      {scheduledUpdates.map((scheduledUpdate, index) => (
        <SubscriptionFutureUpdateRow key={index} futureUpdate={scheduledUpdate} subscription={subscription} />
      ))}
    </SubscriptionBannerContainer>
  );
}
