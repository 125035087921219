import {
  AddonChangeVariables,
  AddonPriceOverrideChangeVariables,
  BillingPeriodChangeVariables,
  CouponChangeVariables,
  DowngradeChangeVariables,
  PlanChangeVariables,
  PlanPriceOverrideChangeVariables,
  SubscriptionDataFragment,
  SubscriptionFutureUpdateDataFragment,
  SubscriptionScheduleType,
  UnitAmountChangeVariables,
} from '@stigg-types/apiTypes';
import React from 'react';
import { Grid } from '@stigg-components';
import { BillingPeriodUpdateRow } from './changeRows/BillingPeriodUpdateRow';
import { PlanChangeUpdateRow } from './changeRows/PlanChangeUpdateRow';
import { UnitAmountUpdateRow } from './changeRows/UnitAmountUpdateRow';
import { MigrationUpdateRow } from './changeRows/MigrationUpdateRow';
import { AddonUpdateRow } from './changeRows/AddonUpdateRow';
import { CouponUpdateRow } from './changeRows/CouponUpdateRow';
import { PriceOverrideUpdateRow } from './changeRows/PriceOverrideUpdateRow';

export function SubscriptionFutureUpdateRow({
  futureUpdate,
  subscription,
  alertVariant,
}: {
  futureUpdate: SubscriptionFutureUpdateDataFragment;
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
}) {
  const { subscriptionScheduleType, scheduleVariables } = futureUpdate;
  switch (subscriptionScheduleType) {
    case SubscriptionScheduleType.Plan:
    case SubscriptionScheduleType.Downgrade:
      return (
        <PlanChangeUpdateRow
          changeVariables={scheduleVariables as PlanChangeVariables | DowngradeChangeVariables}
          subscription={subscription}
          alertVariant={alertVariant}
        />
      );
    case SubscriptionScheduleType.UnitAmount:
      return (
        <UnitAmountUpdateRow
          changeVariables={scheduleVariables as UnitAmountChangeVariables}
          subscription={subscription}
          alertVariant={alertVariant}
        />
      );
    case SubscriptionScheduleType.Addon:
      return (
        <AddonUpdateRow
          changeVariables={scheduleVariables as AddonChangeVariables}
          subscription={subscription}
          alertVariant={alertVariant}
        />
      );
    case SubscriptionScheduleType.Coupon:
      return (
        <CouponUpdateRow
          changeVariables={scheduleVariables as CouponChangeVariables}
          subscription={subscription}
          alertVariant={alertVariant}
        />
      );
    case SubscriptionScheduleType.BillingPeriod:
      return (
        <BillingPeriodUpdateRow
          changeVariables={scheduleVariables as BillingPeriodChangeVariables}
          alertVariant={alertVariant}
        />
      );
    case SubscriptionScheduleType.PriceOverride:
      return (
        <PriceOverrideUpdateRow
          changeVariables={scheduleVariables as PlanPriceOverrideChangeVariables | AddonPriceOverrideChangeVariables}
          subscription={subscription}
          alertVariant={alertVariant}
        />
      );
    case SubscriptionScheduleType.MigrateToLatest:
      return <MigrationUpdateRow subscription={subscription} alertVariant={alertVariant} />;
    default:
      return <Grid />;
  }
  return <Grid />;
}
