import { HighlightText, DottedText, drawFormFields, Field, FormRenderProps, GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { get, isEmpty } from 'lodash';
import {
  PackageStatus,
  Plan,
  PricingType,
  ProductPlanFragment,
  SubscriptionStartSetup,
  TrialPeriodUnits,
} from '@stigg-types/apiTypes';
import React, { useEffect, useMemo } from 'react';
import * as S from './CustomerJourney.style';
import {
  LinkToCreatePlan,
  LinkToEditPlan,
  LinkToPlansPage,
} from '../../../../packages/common/components/PlanTooltipsCollection';
import { TooltipFields } from '../../../../../components/InformationTooltip';
import { CustomerJourneyPlan } from './types';
import { HelpTooltip } from '../../../../../components/HelpTooltip';

export type SubscriptionStartSetupFormFields = {
  subscriptionStartSetup?: SubscriptionStartSetup;
  subscriptionStartTrialPlanId?: string;
  subscriptionStartFreePlanId?: string;
  defaultTrialConfig?: ProductPlanFragment['defaultTrialConfig'];
  trialUnits?: TrialPeriodUnits;
};

export const SubscriptionStartSetupFormFieldsNames: (keyof SubscriptionStartSetupFormFields)[] = [
  'subscriptionStartSetup',
  'subscriptionStartTrialPlanId',
  'subscriptionStartFreePlanId',
];

type SubscriptionStartSetupConfigurationProps = {
  productPlans: CustomerJourneyPlan[];
  productId?: string;
  formRenderProps: FormRenderProps<SubscriptionStartSetupFormFields>;
  readonly?: boolean;
  readonlyHideDottedText?: boolean;
  readonlyTooltip?: TooltipFields;
  highlight?: boolean;
  showTrialPeriod?: boolean;
  excludedStartSetup?: SubscriptionStartSetup[];
  fieldNamePrefix?: string;
  label?: string;
};

function formatSubscriptionStartSetup(subscriptionStartSetup: SubscriptionStartSetup) {
  switch (subscriptionStartSetup) {
    case SubscriptionStartSetup.PlanSelection:
      return t('products.customerJourney.subscriptionStartsPlanSelection');
    case SubscriptionStartSetup.TrialPeriod:
      return t('products.customerJourney.subscriptionStartsTrialPeriod');
    case SubscriptionStartSetup.FreePlan:
      return t('products.customerJourney.subscriptionStartsFreePlan');
    default:
      return `${subscriptionStartSetup}`;
  }
}

function computeTrialPeriodPlanItemOptions(plan: CustomerJourneyPlan): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  if (plan.status === PackageStatus.Draft) {
    return {
      disabled: true,
      tooltipTitle: <Text.B2>{t('products.customerJourney.publishDraftToChoseIt')}</Text.B2>,
    };
  }
  if (!plan.defaultTrialConfig) {
    return {
      disabled: true,
      tooltipTitle: <LinkToEditPlan plan={plan} i18nKey="products.customerJourney.planDoesNotHaveTrialPeriod" />,
    };
  }
  return { disabled: false };
}

function getFormFieldName(fieldName: string, fieldNamePrefix?: string) {
  return fieldNamePrefix ? `${fieldNamePrefix}.${fieldName}` : fieldName;
}

function computeTrialPeriodFields(
  productPlans: CustomerJourneyPlan[],
  values: SubscriptionStartSetupFormFields,
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
  showTrialPeriod: boolean,
  productId?: string,
  fieldNamePrefix?: string,
): Field<SubscriptionStartSetupFormFields>[] {
  const startTrialPlanIdFieldName = getFormFieldName('subscriptionStartTrialPlanId', fieldNamePrefix);
  const defaultTrialConfigFieldName = getFormFieldName('defaultTrialConfig', fieldNamePrefix);
  const selectedPlan = productPlans.find((plan) => plan.id === get(values, startTrialPlanIdFieldName));
  const paidPlansWithTrial = productPlans
    .filter((plan) => plan.pricingType !== PricingType.Free)
    .filter((plan) => plan.defaultTrialConfig);

  const emptyPlansTooltip: TooltipFields | undefined = isEmpty(paidPlansWithTrial)
    ? {
        title: <LinkToPlansPage i18nKey="products.customerJourney.noPaidPlansWithTrial" />,
        placement: 'top',
      }
    : undefined;

  return [
    {
      type: 'inline',
      fields: [
        {
          id: startTrialPlanIdFieldName,
          type: 'searchableSelect',
          variant: 'inline',
          maxWidth: 220,
          placeholder: t('products.customerJourney.planSelectionPlaceholder'),
          disable: readonly || isEmpty(paidPlansWithTrial),
          disabledCursorNotAllowed: readonly || isEmpty(paidPlansWithTrial),
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip || emptyPlansTooltip,
          options: paidPlansWithTrial.map((plan) => {
            const { disabled } = computeTrialPeriodPlanItemOptions(plan);

            return {
              isDisabled: disabled,
              id: plan.id,
              renderSelected: () => (
                <DottedText color="primary">
                  {!productId ? `${plan.product.displayName} / ` : ''}
                  {t(
                    plan.status === PackageStatus.Draft
                      ? 'sharedComponents.planDraftSuffix'
                      : 'sharedComponents.planSuffix',
                    { planName: plan.displayName },
                  )}
                </DottedText>
              ),
              render: (search?: string) => (
                <Text.B2 color="primary">
                  <HighlightText
                    text={`${!productId ? `${plan.product.displayName} / ` : ''}${t(
                      plan.status === PackageStatus.Draft
                        ? 'sharedComponents.planDraftSuffix'
                        : 'sharedComponents.planSuffix',
                      { planName: plan.displayName },
                    )}`}
                    highlight={search}
                  />
                </Text.B2>
              ),
              isInSearch: (search: string) =>
                plan.refId.toLowerCase().includes(search.toLowerCase()) ||
                plan.displayName.toLowerCase().includes(search.toLowerCase()),
            };
          }),
        },
      ],
    },
    {
      type: 'inline',
      hide: () => !showTrialPeriod || !selectedPlan || !get(values, defaultTrialConfigFieldName),
      fields: [
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" display="inline" mr={2}>
              for
            </Text.B2>
          ),
        },
        {
          type: 'custom',
          render: ({ values }) => (
            <GridFlex.RowCenter display="inline-flex">
              <Text.B2 display="inline">
                {get(values, defaultTrialConfigFieldName)!.duration}{' '}
                {get(values, defaultTrialConfigFieldName)!.units === TrialPeriodUnits.Month
                  ? t('pricing.trials.months')
                  : t('pricing.trials.days')}
              </Text.B2>
              {!readonly ? (
                <HelpTooltip $maxWidth={250}>
                  <LinkToEditPlan plan={selectedPlan!} i18nKey="products.customerJourney.editPlanTrialPeriod" />
                </HelpTooltip>
              ) : null}
            </GridFlex.RowCenter>
          ),
        },
      ],
    },
  ];
}

function computeFreePlanItemOptions(plan: CustomerJourneyPlan): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  if (plan.status === PackageStatus.Draft) {
    return {
      disabled: true,
      tooltipTitle: <Text.B2>{t('products.customerJourney.publishDraftToChoseIt')}</Text.B2>,
    };
  }
  return { disabled: false };
}

function computeFreePlanFields(
  productPlans: CustomerJourneyPlan[],
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
  productId?: string,
  fieldNamePrefix?: string,
): Field<SubscriptionStartSetupFormFields>[] {
  const startTrialPlanIdFieldName = getFormFieldName('subscriptionStartFreePlanId', fieldNamePrefix);
  const freePlans = productPlans.filter((plan) => plan.pricingType === PricingType.Free);

  return [
    {
      type: 'inline',
      fields: [
        {
          id: startTrialPlanIdFieldName,
          type: 'searchableSelect',
          variant: 'inline',
          disable: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          placeholder: t('products.customerJourney.planSelectionPlaceholder'),
          displayEmpty: true,
          options: freePlans.map((plan) => {
            const { disabled } = computeFreePlanItemOptions(plan);

            return {
              isDisabled: disabled,
              id: plan.id,
              renderSelected: () => (
                <DottedText color="primary">
                  {!productId ? `${plan.product.displayName} / ` : ''}
                  {t(
                    plan.status === PackageStatus.Draft
                      ? 'sharedComponents.planDraftSuffix'
                      : 'sharedComponents.planSuffix',
                    { planName: plan.displayName },
                  )}
                </DottedText>
              ),
              render: (search?: string) => (
                <Text.B2 color="primary">
                  <HighlightText
                    text={`${!productId ? `${plan.product.displayName} / ` : ''}${t(
                      plan.status === PackageStatus.Draft
                        ? 'sharedComponents.planDraftSuffix'
                        : 'sharedComponents.planSuffix',
                      { planName: plan.displayName },
                    )}`}
                    highlight={search}
                  />
                </Text.B2>
              ),
              isInSearch: (search: string) =>
                plan.refId.toLowerCase().includes(search.toLowerCase()) ||
                plan.displayName.toLowerCase().includes(search.toLowerCase()),
            };
          }),
        },
      ],
    },
  ];
}

function computeSubscriptionStartSetupItemOptions(
  subscriptionStartSetup: SubscriptionStartSetup,
  productPlans: CustomerJourneyPlan[],
  productRefId?: string,
): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  const publishPlans = productPlans.filter((x) => (x as Plan).status === PackageStatus.Published);
  const hasFreePlans = publishPlans.some((x) => (x as Plan).pricingType === PricingType.Free);
  const hasNonFreePlans = publishPlans.some((x) => (x as Plan).pricingType !== PricingType.Free);

  if (subscriptionStartSetup === SubscriptionStartSetup.FreePlan && !hasFreePlans) {
    return {
      disabled: true,
      tooltipTitle: (
        <LinkToCreatePlan productId={productRefId} i18nKey="products.customerJourney.noFreePlansForStartPlan" />
      ),
    };
  }

  if (subscriptionStartSetup === SubscriptionStartSetup.TrialPeriod && !hasNonFreePlans) {
    return {
      disabled: true,
      tooltipTitle: (
        <LinkToCreatePlan productId={productRefId} i18nKey="products.customerJourney.noPaidPlansForStartPlan" />
      ),
    };
  }

  return { disabled: false };
}

function computeFields(
  productPlans: CustomerJourneyPlan[],
  subConfigurationFields: Field<SubscriptionStartSetupFormFields>[],
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
  productId?: string,
  excludedStartSetup?: SubscriptionStartSetup[],
  fieldNamePrefix?: string,
  label?: string,
): Field<SubscriptionStartSetupFormFields>[] {
  const subscriptionStartSetupFieldName = getFormFieldName('subscriptionStartSetup', fieldNamePrefix);
  const startSetupOptions = [
    SubscriptionStartSetup.PlanSelection,
    SubscriptionStartSetup.TrialPeriod,
    SubscriptionStartSetup.FreePlan,
  ].filter((x) => !excludedStartSetup?.includes(x));
  return [
    {
      type: 'inline',
      fields: [
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" display="inline">
              {label || t('products.customerJourney.subscriptionStartsAt')}
            </Text.B2>
          ),
        },
        {
          id: subscriptionStartSetupFieldName,
          type: 'select',
          variant: 'inline',
          withoutPadding: true,
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          placeholder: 'select behavior',
          values: startSetupOptions.map((subscriptionStartSetup) => {
            const { disabled, tooltipTitle } = computeSubscriptionStartSetupItemOptions(
              subscriptionStartSetup,
              productPlans,
              productId,
            );

            return {
              value: subscriptionStartSetup,
              disabled,
              tooltip: {
                title: tooltipTitle || undefined,
              },
              displayValue: formatSubscriptionStartSetup(subscriptionStartSetup),
            };
          }),
        },
        ...subConfigurationFields,
      ],
    },
  ];
}

function useFormFields(
  productPlans: CustomerJourneyPlan[],
  { values }: FormRenderProps<SubscriptionStartSetupFormFields>,
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
  showTrialPeriod: boolean,
  productId?: string,
  excludedStartSetup?: SubscriptionStartSetup[],
  fieldNamePrefix?: string,
  label?: string,
) {
  const subscriptionStartSetupFieldName = getFormFieldName('subscriptionStartSetup', fieldNamePrefix);
  const subscriptionStartsTrialPeriodFields = useMemo(
    () =>
      computeTrialPeriodFields(
        productPlans,
        values,
        readonly,
        readonlyHideDottedText,
        readonlyTooltip,
        showTrialPeriod,
        productId,
        fieldNamePrefix,
      ),
    [
      productPlans,
      values,
      readonly,
      readonlyHideDottedText,
      readonlyTooltip,
      showTrialPeriod,
      productId,
      fieldNamePrefix,
    ],
  );
  const subscriptionStartsFreePlanFields = useMemo(
    () =>
      computeFreePlanFields(
        productPlans,
        readonly,
        readonlyHideDottedText,
        readonlyTooltip,
        productId,
        fieldNamePrefix,
      ),
    [productPlans, readonly, readonlyHideDottedText, readonlyTooltip, productId, fieldNamePrefix],
  );
  const subscriptionStartSetup = get(values, subscriptionStartSetupFieldName);
  const subConfigurationFields = useMemo(() => {
    switch (subscriptionStartSetup) {
      case SubscriptionStartSetup.TrialPeriod:
        return subscriptionStartsTrialPeriodFields;
      case SubscriptionStartSetup.FreePlan:
        return subscriptionStartsFreePlanFields;
      default:
        return [];
    }
  }, [subscriptionStartSetup, subscriptionStartsTrialPeriodFields, subscriptionStartsFreePlanFields]);

  return useMemo<Field<SubscriptionStartSetupFormFields>[]>(
    () =>
      computeFields(
        productPlans,
        subConfigurationFields,
        readonly,
        readonlyHideDottedText,
        readonlyTooltip,
        productId,
        excludedStartSetup,
        fieldNamePrefix,
        label,
      ),
    [
      productPlans,
      productId,
      subConfigurationFields,
      readonly,
      readonlyHideDottedText,
      readonlyTooltip,
      excludedStartSetup,
      fieldNamePrefix,
      label,
    ],
  );
}

function useAutoTrialPeriodFiller(
  productPlans: CustomerJourneyPlan[],
  { values, setFieldValue }: FormRenderProps<SubscriptionStartSetupFormFields>,
  fieldNamePrefix?: string,
) {
  const defaultTrialConfigFieldName = getFormFieldName('defaultTrialConfig', fieldNamePrefix);
  const subscriptionStartSetupFieldName = getFormFieldName('subscriptionStartSetup', fieldNamePrefix);
  const subscriptionStartTrialPlanIdFieldName = getFormFieldName('subscriptionStartTrialPlanId', fieldNamePrefix);
  const subscriptionStartSetup = get(values, subscriptionStartSetupFieldName);
  const subscriptionStartTrialPlanId = get(values, subscriptionStartTrialPlanIdFieldName);

  useEffect(() => {
    if (subscriptionStartSetup === SubscriptionStartSetup.TrialPeriod) {
      const selectedPlan = productPlans.find((plan) => plan.id === subscriptionStartTrialPlanId);
      setFieldValue(defaultTrialConfigFieldName, selectedPlan?.defaultTrialConfig);
    }
  }, [subscriptionStartSetup, subscriptionStartTrialPlanId, productPlans, setFieldValue, defaultTrialConfigFieldName]);
}

export function SubscriptionStartSetupConfiguration({
  productPlans,
  formRenderProps,
  productId,
  readonly = false,
  readonlyHideDottedText = false,
  showTrialPeriod = true,
  readonlyTooltip,
  highlight,
  excludedStartSetup,
  fieldNamePrefix,
  label,
}: SubscriptionStartSetupConfigurationProps) {
  const fields = useFormFields(
    productPlans,
    formRenderProps,
    readonly,
    readonlyHideDottedText,
    readonlyTooltip,
    showTrialPeriod,
    productId,
    excludedStartSetup,
    fieldNamePrefix,
    label,
  );

  useAutoTrialPeriodFiller(productPlans, formRenderProps, fieldNamePrefix);

  const content = <GridFlex.Column>{drawFormFields(fields, formRenderProps)}</GridFlex.Column>;

  return highlight ? (
    <S.StyledBox $isHighlight={highlight} mt={4}>
      {content}
    </S.StyledBox>
  ) : (
    content
  );
}
