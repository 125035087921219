import { EntitlementSummaryFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';

function formatAddons(amountOfAddons: number, firstAddonDisplayName?: string): string {
  if (amountOfAddons === 1) {
    if (firstAddonDisplayName) {
      return t('customers.summarySectionNewAddon', { displayName: firstAddonDisplayName });
    }
    return t('customers.summarySectionAddon', { amountOfAddons });
  }
  if (amountOfAddons > 1) {
    return t('customers.summarySectionAddonPlural', { amountOfAddons });
  }
  return '';
}

function computePackageWithAddonTitle(
  isTrial: boolean,
  originatedFromBasePlan: boolean,
  originatedFromOtherProduct: boolean,
): string {
  if (originatedFromOtherProduct) {
    return 'customers.summarySectionOtherProductPlan';
  }

  if (isTrial) {
    return originatedFromBasePlan
      ? 'customers.summarySectionTrialBasePlanPlusAddons'
      : 'customers.summarySectionTrialPlanPlusAddons';
  }

  return originatedFromBasePlan
    ? 'customers.summarySectionBasePlanPlusAddons'
    : 'customers.summarySectionActivePlanPlusAddons';
}

function computePackageTitle(
  isTrial: boolean,
  originatedFromBasePlan: boolean,
  originatedFromOtherProduct: boolean,
): string {
  if (originatedFromOtherProduct) {
    return 'customers.summarySectionOtherProductPlan';
  }

  if (isTrial) {
    return originatedFromBasePlan ? 'customers.summarySectionTrialBasePlan' : 'customers.summarySectionTrialPlan';
  }

  return originatedFromBasePlan ? 'customers.summarySectionBasePlan' : 'customers.summarySectionActivePlan';
}

export function generateSummaryTitle(
  planPackage: EntitlementSummaryFragment,
  amountOfAddons: number,
  firstAddonDisplayName: string,
  originatedFromBasePlan: boolean,
  originatedFromOtherProduct: boolean,
  isPromotionalEffective: boolean,
  isTrial: boolean,
): string {
  if (isPromotionalEffective) {
    return t('customers.summarySectionPromotional');
  }

  if (planPackage && amountOfAddons > 0) {
    return t(computePackageWithAddonTitle(isTrial, originatedFromBasePlan, originatedFromOtherProduct), {
      formatAddons: formatAddons(amountOfAddons),
    });
  }

  if (planPackage) {
    return t(computePackageTitle(isTrial, originatedFromBasePlan, originatedFromOtherProduct));
  }

  if (amountOfAddons > 0) {
    return formatAddons(amountOfAddons, firstAddonDisplayName);
  }

  return t('customers.summaryUnknown');
}
