import { t } from 'i18next';
import styled from '@emotion/styled/macro';
import {
  CustomerResponseFragment,
  PaymentCollectionMethod,
  PlanFragment,
  PlanListFragment,
  PricingType,
} from '@stigg-types/apiTypes';
import { FormRenderProps, GridFlex, Icon, MenuItem, Select, Text } from '@stigg-components';
import React from 'react';
import { SubscriptionFormFields } from '../SubscriptionForm.types';
import { getPlanDefaultBillingData } from '../SubscriptionForm.utils';
import { ChargeCollectionMethod } from './collectionMethods/ChargeCollectionMethod';
import { InvoiceCollectionMethod } from './collectionMethods/InvoiceCollectionMethod';

type SubscriptionPaymentProps = {
  isUpdatingSubscription: boolean;
  currentPlan: PlanListFragment | PlanFragment | undefined;
  customer: CustomerResponseFragment;
  formProps: FormRenderProps<SubscriptionFormFields>;
};

const PaymentStyledContainer = styled(GridFlex.RowCenter)`
  .MuiSvgIcon-root {
    margin-right: 0;
  }
`;

type PaymentCollectionMethodContentProps = {
  form: FormRenderProps<SubscriptionFormFields>;
  customer: CustomerResponseFragment;
};

type PaymentCollectionMethodOption = {
  renderMethodName: () => JSX.Element;
  renderDetails?: (props: PaymentCollectionMethodContentProps) => JSX.Element;
  isDisabled?: (values: SubscriptionFormFields) => boolean;
};

const COLLECTION_METHOD_OPTIONS: Record<PaymentCollectionMethod, PaymentCollectionMethodOption> = {
  [PaymentCollectionMethod.Charge]: {
    renderMethodName: () => (
      <PaymentStyledContainer gap={2}>
        <Icon icon="Check" color="active" overrideStroke size={18} />
        <Text.B2>{t('subscriptionForm.paymentMethod.payment')}</Text.B2>
      </PaymentStyledContainer>
    ),
    renderDetails: (props) => <ChargeCollectionMethod {...props} />,
  },
  [PaymentCollectionMethod.Invoice]: {
    renderMethodName: () => (
      <PaymentStyledContainer gap={2}>
        <Icon icon="ReceiptOutlined" type="materialIcons" color="active" size={18} />
        <Text.B2>{t('subscriptionForm.paymentMethod.invoice')}</Text.B2>
      </PaymentStyledContainer>
    ),
    renderDetails: (props) => <InvoiceCollectionMethod {...props} />,
  },
  [PaymentCollectionMethod.None]: {
    renderMethodName: () => (
      <PaymentStyledContainer gap={2}>
        <Icon icon="Close" color="active" size={18} />
        <Text.B2>{t('subscriptionForm.paymentMethod.custom')}</Text.B2>
      </PaymentStyledContainer>
    ),
    // this is disabled for credit card trial as backend was not implemented yet (in scheduler)
    isDisabled: (values: SubscriptionFormFields) => !!values.isTrial,
  },
};

export const SubscriptionPayment = ({
  isUpdatingSubscription,
  currentPlan,
  customer,
  formProps,
}: SubscriptionPaymentProps) => {
  const { setFieldValue, values } = formProps;
  const { paymentCollectionMethod } = values;
  const isCustomPlan = currentPlan?.pricingType === PricingType.Custom;

  const handleSubscriptionCustomPricingChange = (collectionMethod: PaymentCollectionMethod) => {
    if (collectionMethod === PaymentCollectionMethod.None) {
      setFieldValue('billingCountryCode', null);
    }

    if (currentPlan) {
      const { billingPeriod, billingCountryCode } = getPlanDefaultBillingData({
        plan: currentPlan,
        customerBillingCurrency: customer.billingCurrency,
      });

      setFieldValue('billingPeriod', billingPeriod);

      if (collectionMethod !== PaymentCollectionMethod.None) {
        setFieldValue('billingCountryCode', billingCountryCode);
      }
    }
  };

  return (
    <GridFlex.Column gap={4} container>
      <Text.H6>{t('subscriptionForm.paymentSectionTitle')}</Text.H6>
      <Select
        disabled={isCustomPlan || isUpdatingSubscription}
        value={paymentCollectionMethod}
        onChange={(e) => setFieldValue('paymentCollectionMethod', e.target.value as PaymentCollectionMethod)}
        renderValue={(value: any) => COLLECTION_METHOD_OPTIONS[value as PaymentCollectionMethod].renderMethodName()}>
        {Object.entries(COLLECTION_METHOD_OPTIONS).map(([key, value]) => (
          <MenuItem
            key={key}
            value={key}
            disabled={value.isDisabled?.(values)}
            onClick={() => handleSubscriptionCustomPricingChange(key as PaymentCollectionMethod)}>
            {value.renderMethodName()}
          </MenuItem>
        ))}
      </Select>
      {paymentCollectionMethod &&
        COLLECTION_METHOD_OPTIONS[paymentCollectionMethod].renderDetails?.({ form: formProps, customer })}
    </GridFlex.Column>
  );
};
