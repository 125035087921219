import { BoundedSearchableSelect, FormControl, OutlinedFormFieldLabel } from '@stigg-components';
import React, { useMemo } from 'react';
import { isUndefined } from 'lodash';
import { TooltipFields } from './InformationTooltip';

export type SearchableSelectOption = {
  id: string;
  render: (search?: string, disabled?: boolean) => string | React.ReactNode;
  renderSelected?: () => string | React.ReactNode;
  isDisabled?: boolean;
  isInSearch: (search: string) => boolean;
};

export type SearchableSelectProps = {
  tooltip?: TooltipFields;
  label?: string;
  menuMaxHeight?: number;
  searchPlaceholder?: string;
  options: SearchableSelectOption[];
  selectedOptionId: string | undefined;
  placeholder?: string;
  variant?: 'inline' | 'outlined';
  maxOptionsToShow?: number;
  onChange: (optionId: string) => void;
  isOptionDisabled?: (optionId: string) => boolean;
  dataTestId?: string;
  disable?: boolean;
  maxWidth?: number;
};

export function SearchableSelect({
  label,
  options,
  variant,
  selectedOptionId,
  placeholder,
  searchPlaceholder,
  maxOptionsToShow,
  onChange,
  isOptionDisabled = () => false,
  dataTestId,
  disable,
  maxWidth,
  menuMaxHeight,
  tooltip,
}: SearchableSelectProps) {
  const [search, setSearch] = React.useState('');

  const selectedOption = useMemo(
    () => options.find((option) => option.id === selectedOptionId),
    [options, selectedOptionId],
  );

  const filteredOptions = useMemo(() => options.filter((option) => option.isInSearch(search)), [options, search]);
  const optionsToShow = useMemo(() => filteredOptions.slice(0, maxOptionsToShow), [filteredOptions, maxOptionsToShow]);
  const isInline = variant === 'inline';

  return (
    <FormControl sx={{ display: 'inline-flex' }} variant="outlined" fullWidth={!isInline}>
      <OutlinedFormFieldLabel label={label} />
      <BoundedSearchableSelect
        tooltip={tooltip}
        disable={disable}
        searchPlaceholder={searchPlaceholder}
        variant={variant}
        maxWidth={maxWidth}
        menuMaxHeight={menuMaxHeight}
        onSearch={setSearch}
        isSearching={false}
        totalItems={filteredOptions.length}
        placeholder={placeholder}
        value={selectedOption}
        items={optionsToShow}
        itemSerializer={({ id }) => id}
        onChange={({ id }) => onChange(id)}
        renderItem={({ id, render }) => render(search, isOptionDisabled(id))}
        renderSelectedItem={({ renderSelected, render }) => (renderSelected ? renderSelected() : render())}
        isOptionDisabled={({ id, isDisabled }) => (isUndefined(isDisabled) ? isOptionDisabled(id) : isDisabled)}
        dataTestId={dataTestId}
      />
    </FormControl>
  );
}
