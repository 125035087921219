import isNil from 'lodash/isNil';
import { Text } from '@stigg-components';
import { t } from 'i18next';
import { CouponFragment, SubscriptionCouponDataFragment, CouponType, Currency, Money } from '@stigg-types/apiTypes';
import { currencyPriceFormatter } from '../../packages/pricing/components/currency/currencyUtils';
import Table, { HeadCell } from '../../../components/table/Table';
import { formatCouponValue } from './utils';

export type CouponCurrencyTableProps = {
  coupon: CouponFragment | SubscriptionCouponDataFragment;
};

const getDiscountTableParams = (coupon: CouponFragment | SubscriptionCouponDataFragment) => {
  const { type, discountValue, percentOff, amountsOff } = coupon;
  const isPercentageDiscount = type === CouponType.Percentage || !isNil(percentOff);

  let data: Money[] = [];
  if (isPercentageDiscount) {
    data = [{ amount: percentOff ?? discountValue, currency: Currency.Usd }];
  } else {
    data = amountsOff ?? (discountValue ? [{ amount: discountValue, currency: Currency.Usd }] : []);
  }

  const headCells: Array<HeadCell<Money, keyof Money>> = [
    {
      id: 'currency',
      label: t('coupons.currenciesDiscountTable.currencyColumn'),
      alignment: 'left',
      width: 300,
      render: (amountOff) => (
        <Text.B2>
          {isPercentageDiscount ? t('coupons.currenciesDiscountTable.allCurrencies') : amountOff.currency}
        </Text.B2>
      ),
    },
    {
      id: 'amount',
      label: t('coupons.currenciesDiscountTable.amountColumn'),
      alignment: 'left',
      render: (amountOff) => (
        <Text.B2>
          {isPercentageDiscount
            ? formatCouponValue(amountOff.amount, CouponType.Percentage, undefined, coupon.durationInMonths)
            : currencyPriceFormatter(amountOff)}
        </Text.B2>
      ),
    },
  ];

  return { data, headCells };
};

export const CouponDiscountTable = ({ coupon }: CouponCurrencyTableProps) => {
  const { data, headCells } = getDiscountTableParams(coupon);

  return <Table rowHeight={65} label={t('coupons.currenciesDiscountTable.title')} data={data} headCells={headCells} />;
};
