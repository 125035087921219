import { styled } from '@stigg-theme';
import { FormControlLabel } from '../index';

export const StyledFormControlLabel = styled(FormControlLabel)<{
  $selected?: boolean;
  $disabled?: boolean;
  $height?: number;
  $disableFlex?: boolean;
}>`
  margin: 0;
  transition: background-color 0.3s ease-in-out;
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
  ${({ theme }) => (theme.isLightTheme ? '' : `background-color: ${theme.itamar.palette.background.darkBackground};`)};

  ${({ $disableFlex }) => !$disableFlex && `flex: 1;`}

  ${({ $height }) => $height && `height: ${$height}px;`}

  ${({ $disabled }) =>
    $disabled &&
    `
    &.Mui-disabled {
      cursor: not-allowed;
    }
  `}

  ${({ $disabled, theme }) => $disabled && `background-color: ${theme.itamar.palette.action.disabled};`}
  ${({ $selected, $disabled, theme }) =>
    $selected &&
    !$disabled &&
    `background-color: ${
      theme.isLightTheme ? theme.itamar.palette.background.lightBackground2 : theme.itamar.palette.action.selected
    };`}

  ${({ $selected, $disabled, theme }) =>
    !$selected &&
    !$disabled &&
    `
    &:hover {
      background-color: ${theme.itamar.palette.action.hover};
    }
  `}
`;
