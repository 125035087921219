import { t } from 'i18next';
import { Card, ExternalLink, GridFlex, Text } from '@stigg-components';
import { PlanFragment } from '@stigg-types/apiTypes';
import { styled } from '@stigg-theme';
import { useNavigation } from '../../../../../../navigation/useNavigation';
import { resolveTrialEndBehavior } from '../../../../../plans/components/PlanTrialDefaultConfiguration';

const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground};
  padding: ${({ theme }) => theme.spacing(8)};
`;

export type SetTrialReferenceToCustomerJourneyProps = {
  plan: PlanFragment;
};

export const SetTrialReferenceToCustomerJourney = ({ plan }: SetTrialReferenceToCustomerJourneyProps) => {
  const navigation = useNavigation();

  return (
    <StyledCard>
      <GridFlex.RowSpaceBetween gap={8}>
        <GridFlex.Item>
          <Text.B2 color="secondary" display="inline">
            {t('products.trials.trialEnds')}{' '}
          </Text.B2>
          <Text.B2 display="inline">{resolveTrialEndBehavior(plan.defaultTrialConfig?.trialEndBehavior)}</Text.B2>
        </GridFlex.Item>
        <GridFlex.Item alignSelf="flex-start">
          <ExternalLink
            label={t('sharedComponents.editInCustomerJourney')}
            url={navigation.composeUrl(navigation.appRoutes.productPage(plan.product.refId), {
              anchor: 'customerJourney',
            })}
          />
        </GridFlex.Item>
      </GridFlex.RowSpaceBetween>
    </StyledCard>
  );
};
