import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { t } from 'i18next';
import { BillingPeriod } from '@stigg-types/apiTypes';
import { SelectValue } from '@stigg-components/types';
import { Field, FormRenderProps } from '@stigg-components';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { AdditionalInputLayout } from '../AdditionalInputLayout';
import { DEFAULT_COMPONENT_WIDTH } from '../consts';
import { SEARCH_COMPONENT_WIDTH } from './consts';

const MAX_VISIBLE_BILLING_CYCLES = 5;

export enum CouponStartOption {
  CurrentBillingCycle = 'current',
  Later = 'later',
}

type AdHocCouponInputProps = {
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
};

const getFutureBillingCycleOptions = (billingPeriod: BillingPeriod | undefined, startDateString: string) => {
  const startDate = moment(startDateString);

  const billingCycleOptions: SelectValue[] = [];

  const numberOfCycleOptions = billingPeriod === BillingPeriod.Monthly ? 12 : 5;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= numberOfCycleOptions; i++) {
    if (billingPeriod === BillingPeriod.Monthly) {
      startDate.add(1, 'months');
      billingCycleOptions.push({
        value: startDate.format('YYYY-MM-DD'),
        displayValue: startDate.format('MMMM YYYY'),
      });
    } else {
      startDate.add(1, 'years');
      billingCycleOptions.push({
        value: startDate.format('YYYY-MM-DD'),
        displayValue: startDate.format('YYYY'),
      });
    }
  }
  return billingCycleOptions;
};

export function FutureStartInput({ formRenderProps }: AdHocCouponInputProps) {
  const {
    values: { billingPeriod, startDate, couponConfiguration },
    setFieldValue,
  } = formRenderProps;

  const { startConfiguration } = couponConfiguration || {};

  const billingCycleOptions = useMemo(
    () => getFutureBillingCycleOptions(billingPeriod, startDate),
    [billingPeriod, startDate],
  );

  useEffect(() => {
    setFieldValue('couponConfiguration.startConfiguration', CouponStartOption.CurrentBillingCycle);
  }, [setFieldValue]);

  useEffect(() => {
    if (couponConfiguration?.startConfiguration === CouponStartOption.Later) {
      setFieldValue('couponConfiguration.startDate', billingCycleOptions[0].value);
    } else {
      setFieldValue('couponConfiguration.startDate', null);
    }
  }, [setFieldValue, couponConfiguration?.startConfiguration, billingCycleOptions]);

  const fields: Field<SubscriptionFormFields>[] = [
    {
      id: 'couponConfiguration.startConfiguration',
      type: 'select',
      gridProps: {
        minWidth: DEFAULT_COMPONENT_WIDTH,
      },
      values: [
        {
          value: CouponStartOption.CurrentBillingCycle,
          displayValue: t('subscriptionForm.discount.startAt.current'),
        },
        {
          value: CouponStartOption.Later,
          displayValue: t('subscriptionForm.discount.startAt.future'),
        },
      ],
    },
    {
      id: 'couponConfiguration.startDate',
      hide: () => startConfiguration !== CouponStartOption.Later,
      type: 'select',
      gridProps: {
        flex: 1,
      },
      restProps: {
        menuMaxHeight: MAX_VISIBLE_BILLING_CYCLES * 35,
      },
      values: billingCycleOptions,
    },
  ];

  return (
    <AdditionalInputLayout
      isSet
      setLabel="Valid from"
      formRenderProps={formRenderProps}
      fields={fields}
      gridProps={{ width: SEARCH_COMPONENT_WIDTH, gap: 10 }} // keep all form elements aligned
      inputGridProps={{ gap: 2, justifyContent: 'flex-start', width: '100%' }}
    />
  );
}
