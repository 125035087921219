import { t } from 'i18next';
import { SubscriptionCancellationTime } from '@stigg-types/apiTypes';

export const downgradeProductTimeFormat = (
  subscriptionCancellationTime?: SubscriptionCancellationTime | null,
  isTrial?: boolean,
  isNotGranted?: boolean,
): string => {
  switch (subscriptionCancellationTime) {
    case SubscriptionCancellationTime.Immediate:
      if (isNotGranted) {
        return t('products.trials.cancelSubscriptionImmediate');
      }
      return t('products.trials.cancelSubscriptionImmediateAndGrant');

    case SubscriptionCancellationTime.EndOfBillingPeriod:
      return isTrial
        ? t('products.trials.cancelSubscriptionEndOfTrialPeriod')
        : t('products.trials.cancelSubscriptionEndOfBillingPeriod');
    default:
      return '';
  }
};
