import { Grid, GridFlex, Icon, InformationTooltip, PackageChip, Text } from '@stigg-components';
import { t } from 'i18next';
import { AlertTriangle } from 'react-feather';
import { PackageStatus, ProductPlanFragment, TrialEndBehavior } from '@stigg-types/apiTypes';
import { formatTrialPeriod } from '../../../common';
import { HiddenIcon } from '../../../packages/common/components/hiddenFromWidgets/HiddenIcon';
import { formatTrialSpendLimit } from '../../../packages/pricing/components/SetPriceWizard/form/utils/trialSpendLimitFormat';

export function PlanOfferingHeaderIcons({
  isHidden,
  isDraft,
  hasPendingChanges,
  trialConfig,
}: {
  isHidden?: boolean;
  isDraft: boolean;
  hasPendingChanges?: boolean;
  trialConfig?: ProductPlanFragment['defaultTrialConfig'];
}) {
  return (
    <>
      {!!isHidden && (
        <Grid item mr={2}>
          <HiddenIcon isHidden size={20} />
        </Grid>
      )}
      {isDraft && (
        <Grid item mt="-2px" mr={2}>
          <PackageChip hasPointer status={PackageStatus.Draft} />
        </Grid>
      )}
      {trialConfig?.duration && (
        <InformationTooltip
          arrow
          placement="top"
          $padding={2}
          title={
            <GridFlex.Column>
              <Text.B2>
                {t('products.trials.defaultTrialPeriod', {
                  formatTrialPeriod: formatTrialPeriod(trialConfig.duration, trialConfig.units),
                }) || ''}
              </Text.B2>
              {trialConfig.budget && (
                <Text.B2>
                  {t('pricing.trials.trialSpendLimit')} {formatTrialSpendLimit(trialConfig.budget.limit)}
                </Text.B2>
              )}
              {trialConfig.trialEndBehavior === TrialEndBehavior.ConvertToPaid && (
                <Text.B2>{t('pricing.trials.automaticallyConvertsToPaidPlan')}</Text.B2>
              )}
            </GridFlex.Column>
          }>
          <Grid item mr={2}>
            <Icon type="custom" icon="HourglassEmpty" size={20} color="default" overrideStroke />
          </Grid>
        </InformationTooltip>
      )}
      {hasPendingChanges && (
        <InformationTooltip
          $padding={2}
          arrow
          placement="top"
          title={<Text.B2>{t('packages.pendingChanges', { packageType: 'plan' }) || ''}</Text.B2>}>
          <AlertTriangle color="#ED6C02" size={20} />
        </InformationTooltip>
      )}
    </>
  );
}
