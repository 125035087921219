import { t } from 'i18next';
import { Text, Field, GridFlex, drawFormFields, FormRenderProps } from '@stigg-components';
import { Auth0CredentialsInput } from '@stigg-types/apiTypes';
import { Auth0IntegrationFormFields } from './useAuth0Form';

type Auth0TenantFormProps = {
  formRenderProps: FormRenderProps<Auth0IntegrationFormFields>;
  hasIntegration: boolean;
};

export function Auth0ClientDetailsForm({ formRenderProps, hasIntegration }: Auth0TenantFormProps) {
  return (
    <GridFlex.Column rowGap={4}>
      {drawFormFields(
        (
          [
            {
              type: 'text',
              id: 'clientDomain',
              label: t('integrations.auth0.steps.tenant.domainLabel'),
              placeholder: t('integrations.auth0.steps.tenant.domainPlaceholder'),
              autoFocus: true,
            },
            {
              type: 'text',
              id: 'clientId',
              label: t('integrations.auth0.steps.tenant.clientIdLabel'),
              placeholder: t('integrations.auth0.steps.tenant.clientIdPlaceholder'),
            },
            {
              type: 'text',
              textFieldType: 'password',
              id: 'clientSecret',
              label: t('integrations.auth0.steps.tenant.clientSecretLabel'),
              placeholder: t('integrations.auth0.steps.tenant.clientSecretLabel'),
            },
          ] as Field<Auth0CredentialsInput>[]
        ).map((field) => ({
          ...field,
          disabled: hasIntegration,
          tooltip: hasIntegration
            ? {
                title: <Text.B2>{t('integrations.auth0.tenantEditDisableTooltip')}</Text.B2>,
                placement: 'top',
              }
            : undefined,
        })),
        formRenderProps,
      )}
    </GridFlex.Column>
  );
}
