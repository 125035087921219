import { Plan, TrialPeriodUnits } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import moment from 'moment';

export const formatTrialPeriod = (duration?: number | null, units?: TrialPeriodUnits | null): string => {
  if (!duration) {
    return t('pricing.trials.noDefaultTrialPeriod');
  }
  if (!units) {
    return '';
  }
  switch (duration) {
    case 0:
      return t('pricing.trials.noDefaultTrialPeriod');
    case 1:
      return `${duration} ${units.toLowerCase()}`;
    default:
      return `${duration} ${units.toLowerCase()}s`;
  }
};
export const diffInDays = (endDate?: Date | null): number => {
  if (!endDate) {
    return 0;
  }
  return moment(endDate).diff(moment(new Date()), 'days', false);
};
export const formatTrialFromDate = (endDate?: Date | null): string => {
  if (!endDate) {
    return '';
  }
  return `${diffInDays(endDate)} days`;
};

export const calculateTrialEndDate = (
  startDate: string,
  duration?: number | null,
  units?: TrialPeriodUnits | null,
): Date => {
  if (!duration || !units) {
    return moment().add(5, 'minute').toDate();
  }
  if (units === TrialPeriodUnits.Month) {
    const endDateMoment = moment(new Date(startDate));
    endDateMoment.add(duration, 'months');
    return endDateMoment.toDate();
  }
  if (units === TrialPeriodUnits.Day) {
    const endDateMoment = moment(new Date(startDate));
    endDateMoment.add(duration, 'days');
    return endDateMoment.toDate();
  }
  return new Date();
};

export const calculateTrialEndDateByPlanDefaultTrial = (startDate: string, plan?: Partial<Plan>): Date => {
  if (!plan) {
    return new Date();
  }
  const duration = plan.defaultTrialConfig?.duration || 30;
  const units = plan.defaultTrialConfig?.units || TrialPeriodUnits.Day;
  return calculateTrialEndDate(startDate, duration, units);
};
