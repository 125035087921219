import { t } from 'i18next';
import { GridFlex, Icon, Text } from '@stigg-components';

export function DescriptionTitle({ isOpen }: { isOpen: boolean }) {
  return (
    <GridFlex.RowCenter>
      <Icon type="reactFeather" icon={isOpen ? 'Minus' : 'Plus'} color="primary.main" size={16} />
      <Text.B2 ml={2} color="primary.main">
        {isOpen ? t('common.description.remove') : t('common.description.add')}
      </Text.B2>
    </GridFlex.RowCenter>
  );
}
