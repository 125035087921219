import { Icon, InformationTooltip, Text } from '@stigg-components';
import { CustomerSubscriptionDataFragment, SubscriptionDataFragment, SubscriptionStatus } from '@stigg-types/apiTypes';
import { t } from 'i18next';

export function shouldShowIcon(subscription: CustomerSubscriptionDataFragment | SubscriptionDataFragment) {
  const isNotStartedTrial = subscription.status === SubscriptionStatus.NotStarted && subscription.trialEndDate;
  const wasSubscriptionInTrialAndHasTerminatedStatus =
    subscription.wasInTrial && [SubscriptionStatus.Canceled, SubscriptionStatus.Expired].includes(subscription.status);

  return isNotStartedTrial || wasSubscriptionInTrialAndHasTerminatedStatus;
}

export const TrialSubscriptionIndicatorIcon = ({
  subscription,
}: {
  subscription: CustomerSubscriptionDataFragment | SubscriptionDataFragment;
}) => {
  if (!shouldShowIcon(subscription)) {
    return null;
  }

  return (
    <InformationTooltip
      arrow
      placement="top"
      $padding={2}
      title={<Text.B2>{t('subscriptions.trialSubscription') || ''}</Text.B2>}>
      <Icon type="custom" icon="HourglassEmpty" color="default" size={18} overrideStroke />
    </InformationTooltip>
  );
};
