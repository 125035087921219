import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { BillingPeriod, CouponFragment, PlanFragment, SubscriptionCouponDataFragment } from '@stigg-types/apiTypes';
import { getPriceBreakdown } from './priceBreakdown';
import { SubscriptionBillableFeatures } from './subscriptionBillableFeatures';
import {
  AddonsPriceOverride,
  BillableFeaturesPriceOverride,
  SubscriptionAddon,
  SubscriptionCouponConfiguration,
} from '../subscriptionForm/SubscriptionForm.types';
import { getPlanPrices } from '../subscriptionForm/SubscriptionForm.utils';
import { useChargesSort } from '../useChargeSort';

export type FreeItem = {
  addonId: string;
  quantity: number;
};

export type UseSubscriptionPriceBreakdownProps = {
  plan?: Pick<PlanFragment, 'prices' | 'minimumSpend'>;
  addons: SubscriptionAddon[];
  paidOneOffAddons?: SubscriptionAddon[];
  billableFeatures?: SubscriptionBillableFeatures;
  billableFeaturesPriceOverride?: BillableFeaturesPriceOverride;
  addonsPriceOverride?: AddonsPriceOverride;
  billingPeriod?: BillingPeriod;
  coupon?: SubscriptionCouponDataFragment | CouponFragment | null;
  couponConfiguration?: SubscriptionCouponConfiguration;
  billingCountryCode?: string | null;
  withZeroQuantityPrices?: boolean;
  freeItems?: FreeItem[] | null;
  minimumSpend?: number | null;
};

export function useSubscriptionPriceBreakdown({
  plan,
  addons,
  paidOneOffAddons,
  billableFeatures,
  billableFeaturesPriceOverride,
  addonsPriceOverride,
  billingPeriod,
  coupon,
  couponConfiguration,
  billingCountryCode,
  withZeroQuantityPrices,
  freeItems,
  minimumSpend,
}: UseSubscriptionPriceBreakdownProps) {
  const planPrices = getPlanPrices({ plan, billingPeriod, billingCountryCode, billableFeaturesPriceOverride });
  const sortedCharges = useChargesSort(compact(planPrices));
  const addedAddons = addons
    .filter((addon) => !!addon.quantity)
    .map((addon) => {
      return {
        price: addonsPriceOverride?.[addon.refId] || addon.price,
        quantity: addon.quantity,
        displayName: addon.displayName,
        addonId: addon.refId,
      };
    });
  const mappedOneOffAddons = paidOneOffAddons?.map((addon) => ({
    price: addonsPriceOverride?.[addon.refId] || addon.price,
    quantity: addon.quantity,
    displayName: addon.displayName,
    addonId: addon.refId,
  }));

  const priceBreakdown = useMemo(
    () =>
      !isEmpty(sortedCharges)
        ? getPriceBreakdown({
            planPrices: sortedCharges,
            addonsPrices: addedAddons,
            billableFeatures,
            coupon,
            couponConfiguration,
            withZeroQuantityPrices,
            paidOneOffAddons: mappedOneOffAddons,
            freeItems,
            minimumSpend,
          })
        : null,
    [
      addedAddons,
      billableFeatures,
      coupon,
      couponConfiguration,
      sortedCharges,
      withZeroQuantityPrices,
      mappedOneOffAddons,
      freeItems,
      minimumSpend,
    ],
  );

  return { priceBreakdown };
}
