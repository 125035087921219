import { PlanFragment, TrialEndBehavior } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import {
  Card,
  EmptyCardContent,
  FormRenderProps,
  Grid,
  GridFlex,
  Icon,
  Link,
  OptionsDropdown,
  Text,
} from '@stigg-components';
import { Edit2, Trash2 } from 'react-feather';
import { formatTrialPeriod, useConfirmationDialog } from '@stigg-common';
import { updatePlanAction } from '../plansSlice';
import { useAppDispatch } from '../../../../redux/store';
import { SetPriceWizardFormFields } from '../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.types';
import { SectionIdentifier } from '../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.steps';
import { formatTrialSpendLimit } from '../../pricing/components/SetPriceWizard/form/utils/trialSpendLimitFormat';

const DEFAULT_TRIAL_END_BEHAVIOR = TrialEndBehavior.CancelSubscription;

export function resolveTrialEndBehavior(trialEndBehavior?: TrialEndBehavior | null) {
  return t(`subscriptionForm.freeTrial.trialEndBehavior.options.${trialEndBehavior || DEFAULT_TRIAL_END_BEHAVIOR}`);
}

type PlanTrialDefaultConfigurationProps = {
  plan: PlanFragment;
  readonly: boolean;
  openSetPriceWizard: (
    section?: SectionIdentifier,
    modifyForm?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void,
  ) => void;
};

export function PlanTrialDefaultConfiguration({
  plan,
  readonly,
  openSetPriceWizard,
}: PlanTrialDefaultConfigurationProps) {
  const dispatch = useAppDispatch();

  const [DeleteTrialDialog, setShowDeleteTrialDialog, deleteTrialDialogProps] = useConfirmationDialog({
    title: t('pricing.deleteTrialDialogTitle'),
    content: t('pricing.deleteTrialDialogContent'),
    handleConfirm: async () => {
      await dispatch(
        updatePlanAction({
          planRefId: plan.refId,
          updatePayload: { defaultTrialConfig: null, id: plan.id },
          silentFetch: true,
        }),
      );
    },
  });

  const trialSetupExists = plan.defaultTrialConfig?.duration;

  const setFreeTrialEnabled = ({ setFieldValue }: FormRenderProps<SetPriceWizardFormFields>) =>
    setFieldValue('freeTrial.enabled', true);

  const onAddTrialClick = () => {
    openSetPriceWizard({ stepId: 'freeTrial' }, setFreeTrialEnabled);
  };

  const onEditTrialClick = () => {
    openSetPriceWizard({ stepId: 'freeTrial' });
  };

  const onDeleteClick = () => {
    setShowDeleteTrialDialog(true);
  };

  return (
    <>
      {!trialSetupExists && (
        <Grid container mt={8}>
          <Grid item mt={2}>
            <Text.H6 ml={2}>{t('pricing.trials.freeTrialTitle')}</Text.H6>
          </Grid>

          <Grid item xs={12} mt={4}>
            <EmptyCardContent maxHeight={53} gap={2}>
              <Icon type="custom" icon="HourglassEmpty" size={24} color="default" overrideStroke />
              <Text.B2>{t('pricing.trials.freeTrialNotSetup')}</Text.B2>
              {!readonly && (
                <Link variant="body2" ml={1} data-testid="add-free-trial-link" onClick={onAddTrialClick}>
                  {t('pricing.trials.addFreeTrial')}
                </Link>
              )}
            </EmptyCardContent>
          </Grid>
        </Grid>
      )}
      {trialSetupExists && (
        <Grid container mt={4}>
          <Grid item display="flex" mt={2}>
            <Text.H6 ml={2}>{t('pricing.trials.freeTrialTitle')}</Text.H6>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Card p={4}>
              <GridFlex.RowSpaceBetweenTop $fullWidth alignItems="center">
                <GridFlex.RowCenter gap={6}>
                  <GridFlex.Column gap={4}>
                    <GridFlex.RowCenter gap={2}>
                      <Icon type="custom" icon="HourglassEmpty" size={24} color="default" overrideStroke />
                      <Text.B2 color="secondary">{t('pricing.trials.defaultTrialTitle')}</Text.B2>
                      <Text.B2>
                        {formatTrialPeriod(plan.defaultTrialConfig?.duration, plan.defaultTrialConfig?.units)}
                      </Text.B2>
                    </GridFlex.RowCenter>

                    {plan.defaultTrialConfig?.budget ? (
                      <GridFlex.RowCenter gap={2}>
                        <Icon icon="MaximumSpend" type="custom" size={24} color="default" overrideStroke overrideFill />
                        <Text.B2 color="secondary">{t('pricing.trials.trialSpendLimit')}</Text.B2>
                        <Text.B2 width={100} mr={6}>
                          {formatTrialSpendLimit(plan.defaultTrialConfig.budget.limit)}
                        </Text.B2>
                      </GridFlex.RowCenter>
                    ) : null}
                  </GridFlex.Column>
                  <GridFlex.Column gap={4}>
                    <GridFlex.RowCenter gap={2}>
                      <Text.B2 color="secondary">{t('products.trials.trialEnds')}</Text.B2>
                      <Text.B2>{resolveTrialEndBehavior(plan.defaultTrialConfig?.trialEndBehavior)}</Text.B2>
                    </GridFlex.RowCenter>

                    {plan.defaultTrialConfig?.budget ? (
                      <GridFlex.RowCenter gap={2}>
                        <Text.B2 color="secondary">{t('products.trials.spendLimitReached')}</Text.B2>
                        <Text.B2>{t('products.trials.denyOnDemandCharges')}</Text.B2>
                      </GridFlex.RowCenter>
                    ) : null}
                  </GridFlex.Column>
                </GridFlex.RowCenter>

                {!readonly && (
                  <Grid item>
                    <OptionsDropdown
                      options={[
                        {
                          icon: Edit2,
                          text: t('sharedComponents.edit'),
                          onClick: onEditTrialClick,
                        },
                        {
                          icon: Trash2,
                          text: t('sharedComponents.remove'),
                          onClick: onDeleteClick,
                        },
                      ]}
                    />
                  </Grid>
                )}
              </GridFlex.RowSpaceBetweenTop>
            </Card>
          </Grid>
        </Grid>
      )}
      <DeleteTrialDialog {...deleteTrialDialogProps} />
    </>
  );
}
