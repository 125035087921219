import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { t } from 'i18next';
import { includes, compact } from 'lodash';
import {
  AddonFragment,
  BillingPeriod,
  CustomerSubscriptionDataFragment,
  PlanCompatiblePackageGroupFragment,
  PriceFragment,
} from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { Box, Grid, Text, TwoLinesLayout } from '@stigg-components';
import head from 'lodash/head';
import { RootState } from '../../../../../../../redux/store';
import { SubscriptionAddon } from '../SubscriptionForm.types';
import { AutocompleteSearch } from '../../../../../../packages/plans/components/AutoCompleteSearch';
import { getBillingCadenceIconTooltip } from '../../../../../../packages/pricing/components/SetPriceWizard/form/billingCadenceStep/BillingCadenceStep.utils';
import { convertToAddonType } from '../SubscriptionForm.utils';

export type AddonAutoCompleteSearchProps = {
  billingPeriod?: BillingPeriod;
  billingCountryCode?: string | null;
  currentPackageGroup?: PlanCompatiblePackageGroupFragment;
  isCustomSubscription?: boolean;
  subscription: CustomerSubscriptionDataFragment | null | undefined;
  addonsMap: Map<string, AddonFragment>;
  push: (obj: SubscriptionAddon) => void;
  onChange?: (obj: SubscriptionAddon) => void;
};

export type AutocompleteSearchComponentChooseAddonProps = {
  push: (obj: SubscriptionAddon) => void;
  onChange?: (obj: SubscriptionAddon) => void;
  option: AddonFragment | null;
};

const renderAddonOption = (props: any, option: AddonFragment, billingPeriod?: BillingPeriod) => {
  let addonHasNoMatchingPrice = false;
  if (billingPeriod) {
    addonHasNoMatchingPrice = !option.prices?.some((addonPrice) => addonPrice.billingPeriod === billingPeriod);
  }

  const billingCadence = head(option.prices)?.billingCadence;

  return (
    <Box component="li" {...props} key={option.refId}>
      <Grid alignItems="center" justifyContent="space-between" container spacing={6}>
        <Grid item gap={3}>
          {billingCadence &&
            getBillingCadenceIconTooltip({
              billingCadence,
              selected: false,
            })}
          <TwoLinesLayout firstRow={option.displayName} secondRow={option.refId || ''} />
        </Grid>
        <Grid item sx={{ color: (theme) => theme.itamar.palette.grey[700] }}>
          <Text.B2>
            {props['aria-disabled']
              ? addonHasNoMatchingPrice
                ? `(${t('subscriptions.unsupportedBillingPeriod')})`
                : `(${t('subscriptions.alreadyAdded')})`
              : ''}
          </Text.B2>
        </Grid>
      </Grid>
    </Box>
  );
};

const findAddonPrice = (addon: AddonFragment, billingPeriod: BillingPeriod, billingCountryCode: string | null) =>
  addon.prices?.find(
    (price) => price.billingPeriod === billingPeriod && price.billingCountryCode === billingCountryCode,
  );

export const AddonAutoCompleteSearch = ({
  addonsMap,
  billingCountryCode,
  billingPeriod,
  subscription,
  isCustomSubscription,
  currentPackageGroup,
  push,
  onChange,
}: AddonAutoCompleteSearchProps) => {
  const { addonDependenciesAny: isAddonDependencyAnyRuleEnabled } = useFlags<FeatureFlags>();
  const isFetchingAddons = useSelector((state: RootState) => state.addonReducer.isLoading);
  const { edges: addonEdges } = useSelector((state: RootState) => state.addonReducer.addons);
  const [search, setSearch] = useState<string | null>(null);
  const existingSubscriptionAddons = subscription?.addons;

  const { allAddons, searchAddons: tempSearchAddons } = useMemo(() => {
    const allAddons =
      addonEdges
        ?.map((edge) => edge.node)
        ?.filter((addon) =>
          currentPackageGroup
            ? currentPackageGroup.addons?.some((groupAddon) => groupAddon.refId === addon.refId)
            : true,
        ) || [];

    const searchAddons = allAddons.filter(({ displayName, refId }) => {
      if (!search) {
        return true;
      }

      return (
        displayName.toLowerCase().includes(search.toLowerCase()) || refId.toLowerCase().includes(search.toLowerCase())
      );
    });

    return { allAddons, searchAddons };
  }, [addonEdges, currentPackageGroup, search]);

  const hasPrices = !!billingPeriod && !isCustomSubscription;
  let searchAddons = tempSearchAddons;
  if (hasPrices) {
    searchAddons = searchAddons.filter(({ prices }) =>
      prices?.some((price) => price.billingCountryCode === billingCountryCode),
    );
  }

  const onSearchKeyUp = (event: any) => {
    const search = event.target.value || null;
    setSearch(search === '' ? null : search);
  };

  const chooseAddon = useCallback(
    ({ push, option, onChange }: AutocompleteSearchComponentChooseAddonProps) => {
      const getAddonFields = (addon: AddonFragment) => {
        let price: PriceFragment | null | undefined = null;
        if (hasPrices) {
          price = findAddonPrice(addon, billingPeriod, billingCountryCode || null);
        }
        const existingSubscriptionAddon = existingSubscriptionAddons?.find(
          (subscriptionAddon) => subscriptionAddon.addon.refId === addon.refId,
        );

        const addonTypeData = convertToAddonType(addon);

        return { price, existingSubscriptionAddon, addonTypeData };
      };

      if (!option) {
        return;
      }

      const { price, existingSubscriptionAddon, addonTypeData } = getAddonFields(option);

      addonsMap.set(option.id, option);

      const newItem = {
        ...option,
        price: existingSubscriptionAddon ? existingSubscriptionAddon.price : price,
        ...addonTypeData,
        dependencies:
          option.dependencies?.map((dependency) => {
            const { price, existingSubscriptionAddon, addonTypeData } = getAddonFields(dependency);
            return {
              ...option,
              price: existingSubscriptionAddon ? existingSubscriptionAddon.price : price,
              ...addonTypeData,
              dependencies: undefined,
            };
          }) || [],
      };

      if (onChange) {
        onChange(newItem);
      } else {
        push(newItem);
      }

      setSearch(null);

      if (isAddonDependencyAnyRuleEnabled) {
        return;
      }

      const dependentAddonsToAdd =
        option?.dependencies
          ?.filter(
            (addonDependency) =>
              !addonsMap.has(addonDependency.id) && allAddons.some((addon) => addon.refId === addonDependency.refId),
          )
          ?.map((dependency) => {
            // Only root addons have dependencies list, so we resolve the dependency from the root addons list
            return allAddons.find((addon) => addon.refId === dependency.refId) as AddonFragment;
          }) || [];

      compact(dependentAddonsToAdd).forEach((dependentAddon) => {
        chooseAddon({ push, option: dependentAddon });
      });
    },
    [
      addonsMap,
      allAddons,
      billingCountryCode,
      billingPeriod,
      existingSubscriptionAddons,
      hasPrices,
      isAddonDependencyAnyRuleEnabled,
    ],
  );

  const isOptionDisabled = useCallback(
    (option: AddonFragment) => {
      let addonHasNoMatchingPrice = false;
      if (hasPrices) {
        addonHasNoMatchingPrice = !option.prices?.some(
          (addonPrice) =>
            addonPrice.billingPeriod === billingPeriod && addonPrice.billingCountryCode === billingCountryCode,
        );
      }

      const selectedAddonIds = Array.from(addonsMap.keys());

      return includes(selectedAddonIds, option.id) || addonHasNoMatchingPrice;
    },
    [addonsMap, billingCountryCode, billingPeriod, hasPrices],
  );

  return (
    <AutocompleteSearch
      noOptionsText={<Text.B2 color="disabled">{t('subscriptions.noCompatibleAddons')}</Text.B2>}
      chooseOption={(option) => chooseAddon({ push, option, onChange })}
      isOptionDisabled={(option: AddonFragment) => isOptionDisabled(option)}
      searchOptions={searchAddons}
      onSearchKeyUp={onSearchKeyUp}
      isFetching={isFetchingAddons}
      placeholder={t('addons.searchPlaceholder')}
      getOptionLabel={(option) => `${option.displayName} (${option.refId})`}
      renderOption={(props: any, option: AddonFragment) => renderAddonOption(props, option, billingPeriod)}
      isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
    />
  );
};
