import { t } from 'i18next';
import { Field, FormRenderProps, Text, GridFlex } from '@stigg-components';
import { Currency } from '@stigg-types/apiTypes';
import { SubscriptionFormFields } from '../SubscriptionForm.types';
import { AdditionalInputActionParams, AdditionalInputLayout } from './AdditionalInputLayout';
import { getCurrencySymbol } from '../../../../../../packages/pricing/components/currency/currencyUtils';

type BudgetSpendingConfigurationProps = {
  currentCurrency: Currency;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
  withMaximumSpend?: boolean;
  withMinimumSpend?: boolean;
  withHeader?: boolean;
  withIcon?: boolean;
  maxSpendEndAdornment?: 'billingPeriod' | 'currency';
  maxSpendPlaceholder?: string;
};

export function BudgetSpendingConfiguration({
  currentCurrency,
  formRenderProps,
  withMinimumSpend = true,
  withMaximumSpend = true,
  withHeader = true,
  withIcon = true,
  maxSpendEndAdornment = 'billingPeriod',
  maxSpendPlaceholder = t(`subscriptionForm.budgetSpendingConfiguration.maximumSpend.limitPlaceholder`),
}: BudgetSpendingConfigurationProps) {
  const {
    values: { billingPeriod, minimumSpend, budget },
  } = formRenderProps;

  const onSetBudget = ({ setFieldValue }: AdditionalInputActionParams) => {
    setFieldValue('budget', { limit: undefined, hasSoftLimit: false });
  };

  const onUnsetBudget = ({ setFieldValue, setFieldTouched }: AdditionalInputActionParams) => {
    setFieldValue('budget', undefined, true);
    setTimeout(() => setFieldTouched('budget', false, true));
  };

  const onSetMinimumSpend = ({ setFieldValue }: AdditionalInputActionParams) => {
    setFieldValue('minimumSpend', { limit: undefined });
  };

  const onUnsetMinimumSpend = ({ setFieldValue, setFieldTouched }: AdditionalInputActionParams) => {
    setFieldValue('minimumSpend', undefined, true);
    setTimeout(() => setFieldTouched('minimumSpend', false, true));
  };

  const minimumSpendFields: Field<SubscriptionFormFields>[] = [
    {
      id: 'minimumSpend.minimum.amount',
      type: 'text',
      textFieldType: 'number',
      placeholder: t(`subscriptionForm.budgetSpendingConfiguration.minimumSpend.amountPlaceholder`),
      startAdornment: getCurrencySymbol(currentCurrency),
      endAdornment: t(`subscriptionForm.budgetSpendingConfiguration.maximumSpend.billingPeriod.${billingPeriod}`),
      autoFocus: true,
      hideErrorText: true,
      isNumberWithoutSigns: true,
      gridProps: { width: 260 },
    },
  ];

  const maximumSpendFields: Field<SubscriptionFormFields>[] = [
    {
      id: 'budget.limit',
      type: 'text',
      textFieldType: 'number',
      placeholder: maxSpendPlaceholder,
      startAdornment: maxSpendEndAdornment === 'billingPeriod' ? getCurrencySymbol(currentCurrency) : undefined,
      endAdornment:
        maxSpendEndAdornment === 'billingPeriod'
          ? t(`subscriptionForm.budgetSpendingConfiguration.maximumSpend.billingPeriod.${billingPeriod}`)
          : `${getCurrencySymbol(currentCurrency)} ${currentCurrency}`,
      autoFocus: true,
      hideErrorText: true,
      isNumberWithoutSigns: true,
      gridProps: { width: 260 },
    },
  ];

  return (
    <GridFlex.Column gap={2}>
      {withHeader ? (
        <GridFlex.Column gap={1}>
          <Text.H6>{t('subscriptionForm.budgetSpendingConfiguration.title')}</Text.H6>
          <Text.B2>{t('subscriptionForm.budgetSpendingConfiguration.subtitle')}</Text.B2>
        </GridFlex.Column>
      ) : null}

      {withMinimumSpend ? (
        <AdditionalInputLayout
          isSet={!!minimumSpend}
          setIcon={withIcon ? { icon: 'MinimumSpend', overrideStroke: true, overrideFill: true } : undefined}
          unsetIcon={{ icon: 'MinimumSpend', color: 'disabled', overrideStroke: true, overrideFill: true }}
          setLabel={t('subscriptionForm.budgetSpendingConfiguration.minimumSpend.label')}
          buttonLabel={t('sharedComponents.add')}
          fields={minimumSpendFields}
          onSet={onSetMinimumSpend}
          onUnset={onUnsetMinimumSpend}
          formRenderProps={formRenderProps}
        />
      ) : null}
      {withMaximumSpend ? (
        <AdditionalInputLayout
          isSet={!!budget}
          setIcon={withIcon ? { icon: 'MaximumSpend', overrideStroke: true, overrideFill: true } : undefined}
          unsetIcon={{ icon: 'MaximumSpend', color: 'disabled', overrideStroke: true, overrideFill: true }}
          setLabel={t('subscriptionForm.budgetSpendingConfiguration.maximumSpend.label')}
          buttonLabel={t('sharedComponents.add')}
          fields={maximumSpendFields}
          onSet={onSetBudget}
          onUnset={onUnsetBudget}
          formRenderProps={formRenderProps}
        />
      ) : null}
    </GridFlex.Column>
  );
}
