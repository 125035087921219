import React, { ReactNode, useState } from 'react';
import { ExternalLink, Collapse, GridFlex, IconButton, IconButtonProps, Text } from '@stigg-components';
import { t } from 'i18next';
import { isFunction } from 'lodash';
import styled from 'styled-components/macro';
import { ChevronRight } from 'react-feather';
import { TextColor } from '@stigg-theme';
import isString from 'lodash/isString';
import { HelpTooltip } from './HelpTooltip';
import { GridProps } from './Grids';

export const CollapsableSectionIcon = styled(ChevronRight)<{ $isOpen: boolean; $errorColor?: boolean; $size?: number }>`
  height: ${({ $size = 24 }) => `${$size}px`};
  width: ${({ $size = 24 }) => `${$size}px`};
  color: ${({ $errorColor, theme }) =>
    $errorColor ? theme.itamar.palette.error.main : theme.itamar.palette.action.active};
  transition: all 0.2s ease-in;
  ${({ $isOpen }) => $isOpen && `transform: rotate(90deg)`}
`;

type CollapsableSectionProps = {
  title: ReactNode | ((isOpen: boolean) => ReactNode);
  onClick?: () => void;
  isSectionOpen?: boolean;
  content: ReactNode;
  gap?: number;
  color?: TextColor;
  titleTextProps?: React.ComponentProps<typeof Text.B2>;
  titleHelperTooltip?: string;
  titleHelperTooltipLink?: string;
  buttonSx?: IconButtonProps['sx'];
  sx?: GridProps['sx'];
  titleGridSx?: GridProps['sx'];
  $fullWidth?: boolean;
  hideIcon?: boolean;
  iconSize?: number;
  dataTestId?: string;
};

export function CollapsableSection({
  title,
  titleTextProps,
  titleHelperTooltip,
  titleHelperTooltipLink,
  onClick,
  isSectionOpen,
  content,
  color,
  gap = 2,
  buttonSx,
  sx,
  titleGridSx,
  $fullWidth,
  hideIcon,
  iconSize,
  dataTestId,
}: CollapsableSectionProps) {
  const [isOpen, setIsOpen] = useState(!!isSectionOpen);

  const onClickOverride = () => {
    setIsOpen(!isOpen);
    if (onClick) {
      onClick();
    }
  };

  const titleComponent = isString(title) ? (
    <Text.H6 color={color} {...(titleTextProps || {})}>
      {title}
    </Text.H6>
  ) : isFunction(title) ? (
    title(isOpen)
  ) : (
    title
  );

  return (
    <GridFlex.Column $fullWidth={$fullWidth} gap={gap} sx={sx} data-testid={dataTestId}>
      <GridFlex.RowCenter alignItems="center" $fullWidth={$fullWidth} sx={titleGridSx}>
        <GridFlex.RowCenter sx={{ cursor: 'pointer' }} $fullWidth={$fullWidth} onClick={onClickOverride}>
          {titleComponent}
          {titleHelperTooltip && (
            <HelpTooltip $maxWidth={250}>
              <>
                <Text.B2 mb={2}>{titleHelperTooltip}</Text.B2>
                {titleHelperTooltipLink && (
                  <ExternalLink label={t('sharedComponents.learnMore')} url={titleHelperTooltipLink} />
                )}
              </>
            </HelpTooltip>
          )}
        </GridFlex.RowCenter>
        {!hideIcon && (
          <IconButton sx={buttonSx} onClick={onClickOverride} data-testid="toggle-section" data-isopen={isOpen}>
            <CollapsableSectionIcon $isOpen={isOpen} $errorColor={color === 'error'} $size={iconSize} />
          </IconButton>
        )}
      </GridFlex.RowCenter>
      <Collapse in={isOpen}>{content}</Collapse>
    </GridFlex.Column>
  );
}
