import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MemberStatus } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchUserAction, registerMemberAction } from '../authSlice';
import Loader from '../../../components/Loader';
import { useAnalytics } from '../../common/useAnalytics';

function ConfirmRegisterMemberPage() {
  const dispatch = useAppDispatch();
  const isRegistering = useSelector((state: RootState) => state.authReducer.isRegisteringMember);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const { track, group } = useAnalytics();

  // todo: support multiple pending memberships, currently you just join the 1st one
  const pendingMembership =
    user && user.memberships.find((membership) => membership.memberStatus === MemberStatus.Invited);

  useEffect(() => {
    const registerUser = async () => {
      if (pendingMembership) {
        await dispatch(registerMemberAction());
        await dispatch(fetchUserAction());
        track('Team member invitation accepted');

        const { account } = pendingMembership;
        group(account.id, {
          company_name: account.displayName,
        });

        // let segment events flush
        window.setTimeout(() => window.location.assign('/'), 3000);
      } else {
        window.location.assign('/');
      }
    };

    void registerUser();
  }, [dispatch, isRegistering, pendingMembership, track, group, account]);

  return <Loader />;
}

export default ConfirmRegisterMemberPage;
