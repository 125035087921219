import { EntitlementSummaryFragment, SubscriptionStatus } from '@stigg-types/apiTypes';
import { isBasePlan } from './entitlementSummary.utils';

export enum EntitlementMethod {
  PRICE = 'PRICE',
  PROMOTIONAL = 'PROMOTIONAL',
  ADDON = 'ADDON',
  PLAN = 'PLAN',
  BASE_PLAN = 'BASE_PLAN',
  OTHER_PRODUCT_PLAN = 'OTHER_PRODUCT_PLAN',
  UNKNOWN = 'UNKNOWN',
}

export function isOriginatedFromPlan(method: EntitlementMethod) {
  return (
    method === EntitlementMethod.PLAN ||
    method === EntitlementMethod.BASE_PLAN ||
    method === EntitlementMethod.OTHER_PRODUCT_PLAN
  );
}

export function getEntitlementMethod(
  summary: EntitlementSummaryFragment,
  activePlanIds: string[],
  trialPlanIds: string[],
  focusedProductId?: string,
): EntitlementMethod {
  if (summary.priceEntitlement) {
    return EntitlementMethod.PRICE;
  }
  if (summary.featurePromotionalEntitlement) {
    return EntitlementMethod.PROMOTIONAL;
  }

  const packageType = summary?.featurePackageEntitlement?.package?.type;

  if (packageType === 'Plan' && summary?.plan) {
    if (focusedProductId && summary.plan.product.refId !== focusedProductId) {
      return EntitlementMethod.OTHER_PRODUCT_PLAN;
    }
    const isTrial = summary?.subscription?.status === SubscriptionStatus.InTrial;
    if (isBasePlan(isTrial, summary.plan.refId, activePlanIds, trialPlanIds)) {
      return EntitlementMethod.BASE_PLAN;
    }
    return EntitlementMethod.PLAN;
  }

  if (packageType === 'Addon') {
    return EntitlementMethod.ADDON;
  }

  return EntitlementMethod.UNKNOWN;
}
