import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FeatureInput,
  CreateFeatureMutation,
  CreateFeatureMutationVariables,
  ErrorCode,
  FeatureCreatedFragment,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchFeaturesAction } from '../featuresSlice';
import { navigateTo } from '../../navigation/actions';
import { appRoutes } from '../../navigation/useNavigation';
import { ExecuteOperationOptions } from '../../common';

const CREATE_FEATURE = gql`
  mutation CreateFeature($input: FeatureInput!) {
    createFeature(input: $input) {
      ...FeatureCreatedFragment
    }
  }
  fragment FeatureCreatedFragment on Feature {
    id
    displayName
    description
    updatedAt
    refId
    featureStatus
    environmentId
    featureType
    meterType
    featureUnits
    featureUnitsPlural
    hasMeter
  }
`;

type CreateFeatureProps = {
  featureData: Omit<FeatureInput, 'environmentId'>;
  hideErrorMessages?: boolean;
} & ExecuteOperationOptions;

async function createFeature(
  {
    featureData,
    navigateToEntity = true,
    withSuccessMessage = true,
    hideErrorMessages = false,
    silentFetch,
  }: CreateFeatureProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<FeatureCreatedFragment> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();

      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const response = await apolloClient.mutate<CreateFeatureMutation, CreateFeatureMutationVariables>({
        mutation: CREATE_FEATURE,
        variables: {
          input: { ...featureData, environmentId: accountReducer.currentEnvironmentId },
        },
      });

      await dispatch(fetchFeaturesAction({ environmentId: accountReducer.currentEnvironmentId, silentFetch }));

      const newFeature = response.data?.createFeature;
      if (newFeature && navigateToEntity) {
        dispatch(navigateTo(appRoutes.featuresPage()));
      }

      return newFeature;
    },
    {
      successMessage: withSuccessMessage ? t('features.api.successCreate') : undefined,
      failureMessageHandler: () => t('features.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('features.api.failCreateDupFeature', {
          featureId: featureData.refId,
        }),
      },
      hideErrorMessages,
    },
    dispatch,
  );
}

export { createFeature };
