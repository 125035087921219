import { t } from 'i18next';
import { flushSync } from 'react-dom';
import { useStiggTheme, THEMES } from '@stigg-theme';
import { useHover } from '@stigg-common';
import { InformationTooltip, Text } from '@stigg-components';
import { Around } from '@theme-toggles/react';

import '@theme-toggles/react/css/Around.css';
import { useAnalytics } from '../modules/common/useAnalytics';

export function ThemeToggle() {
  const { track } = useAnalytics();
  const [themeSwitchHoverRef, isThemeSwitchHover, refObject] = useHover();
  const { theme, changeThemeVariant } = useStiggTheme();

  const toggleDarkMode = async () => {
    const themeToSwitchTo = theme.isLightTheme ? THEMES.DARK : THEMES.LIGHT;

    track('Theme changed', { selected_theme: themeToSwitchTo });

    // @ts-ignore
    if (!refObject.current || !document.startViewTransition) {
      changeThemeVariant(themeToSwitchTo);
      return;
    }

    // @ts-ignore
    await document.startViewTransition(() => {
      flushSync(() => {
        changeThemeVariant(themeToSwitchTo);
      });
    }).ready;

    const { top, left, width, height } = refObject.current.getBoundingClientRect();
    const right = window.innerWidth - left;
    const bottom = window.innerHeight - top;
    const maxRadius = Math.hypot(Math.max(left, right), Math.max(top, bottom));

    document.documentElement.animate(
      {
        clipPath: [
          `circle(0px at ${Number(left) + Number(width) / 2}px ${Number(top) + Number(height) / 2}px)`,
          `circle(${maxRadius}px at ${Number(left) + Number(width) / 2}px ${Number(top) + Number(height) / 2}px)`,
        ],
      },
      {
        duration: 1000,
        easing: 'ease-in-out',
        pseudoElement: '::view-transition-new(root)',
      },
    );
  };

  return (
    <InformationTooltip
      $padding={2}
      title={<Text.B2>{t('themeToggle.tooltip')}</Text.B2>}
      arrow
      placement="bottom-start">
      <Around
        ref={themeSwitchHoverRef}
        color={theme.itamar.palette.primary.main}
        duration={750}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 24,
          color: theme.itamar.palette.primary.main,
          transition: 'border 0.3s ease-in-out',
          border: `1px solid ${
            isThemeSwitchHover ? theme.itamar.palette.primary.main : theme.itamar.palette.other.outlineBorderLight
          }`,
          borderRadius: theme.itamar.border.radius,
          width: 40,
        }}
        toggled={!theme.isLightTheme}
        toggle={() => toggleDarkMode()}
      />
    </InformationTooltip>
  );
}
