import { t } from 'i18next';
import { Alert, Icon, Box, Text, WizardAccordionStep, ExternalLink } from '@stigg-components';
import { Auth0CredentialsFragment } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../redux/store';
import { fetchIntegrationByVendorAction } from '../../integrationsSlice';
import { VendorIdentifier } from '../../constants';
import { Auth0Applications } from './Auth0Applications';
import { Auth0ClientDetailsForm } from './Auth0ClientDetailsForm';
import { useAuth0Form, Auth0IntegrationFormFields } from './useAuth0Form';
import { Auth0InitialCustomerAccess } from './Auth0InitialCustomerAccess';
import { useGetAuth0Applications } from '../../queries/fetchAuth0Applications';

type UseWizardStepsProps = {
  hasIntegration: boolean;
  credentials: Auth0CredentialsFragment | undefined;
  onAddIntegration: (credentials: Auth0IntegrationFormFields) => Promise<void>;
};

export const useAuth0IntegrationWizardSteps = ({
  hasIntegration,
  credentials,
  onAddIntegration,
}: UseWizardStepsProps): WizardAccordionStep[] => {
  const dispatch = useAppDispatch();

  const formRenderProps = useAuth0Form(credentials, onAddIntegration);
  const {
    createMAUFeature,
    values,
    isValid,
    dirty,
    errors,
    isClientDetailsValid,
    isInitialCustomerAccessStepSelectionValid,
    individualShowMissingMAUEntitlement,
    organizationShowMissingMAUEntitlement,
    submitForm,
  } = formRenderProps;

  const { data, refetch: fetchAuth0Applications } = useGetAuth0Applications({
    clientId: values.clientId,
    clientSecret: values.clientSecret,
    clientDomain: values.clientDomain,
  });

  return [
    {
      id: 'stigg-client-in-auth0',
      title: t('integrations.auth0.steps.clientSetup.title'),
      content: (
        <Box>
          <Alert
            icon={<Icon type="custom" icon="InstallPackageIcon" color="primary.main" overrideStroke />}
            sx={{ backgroundColor: (theme) => theme.itamar.palette.grey[25], width: '100%' }}
            severity="info">
            <Text.B2 gap={1}>
              <ExternalLink
                label={t('integrations.auth0.steps.clientSetup.installationUrlLinkText')}
                url={t('integrations.auth0.steps.clientSetup.installationUrl')}
              />
            </Text.B2>
          </Alert>
        </Box>
      ),
      maxWidth: 600,
    },
    {
      id: 'auth0-client-details',
      title: t('integrations.auth0.steps.tenant.title'),
      onStepContinue: async () => {
        await createMAUFeature();
        try {
          await fetchAuth0Applications({ throwOnError: true });
        } catch (e: any) {
          // In case we failed to fetch auth0 applications we should not allow to continue
          return { canContinue: false };
        }
        return { canContinue: true };
      },
      hasErrors: !dirty || isClientDetailsValid,
      summary: values?.clientDomain,
      content: <Auth0ClientDetailsForm formRenderProps={formRenderProps} hasIntegration={hasIntegration} />,
      maxWidth: 600,
    },
    {
      id: 'auth0-application',
      title: t('integrations.auth0.steps.application.title'),
      hasErrors: !dirty || !!errors.applicationId,
      summary: `${values.applicationName} (${t(`integrations.auth0ApplicationTypes.${values.applicationType}`)})`,
      content: (
        <Auth0Applications
          applications={
            hasIntegration
              ? [
                  {
                    appId: credentials!.applicationId,
                    name: credentials!.applicationName,
                    type: credentials!.applicationType,
                  },
                ]
              : data
          }
          formRenderProps={formRenderProps}
          hasIntegration={hasIntegration}
        />
      ),
    },
    {
      id: 'auth0-initial-customer-access',
      title: t('integrations.auth0.steps.initialCustomerAccess.title'),
      hasErrors: !dirty || !isInitialCustomerAccessStepSelectionValid() || !isValid,
      nextButtonText: t('integrations.auth0.completeButtonText'),
      content: (
        <Auth0InitialCustomerAccess
          formRenderProps={formRenderProps}
          hasIntegration={hasIntegration}
          individualShowMissingMAUEntitlement={individualShowMissingMAUEntitlement}
          organizationShowMissingMAUEntitlement={organizationShowMissingMAUEntitlement}
        />
      ),
      onStepContinue: async () => {
        await submitForm();
        await dispatch(
          fetchIntegrationByVendorAction({
            vendorIdentifier: VendorIdentifier.Auth0,
          }),
        );
      },
    },
  ];
};
