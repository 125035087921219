import { t } from 'i18next';
import { useMemo } from 'react';
import { isEmpty, compact } from 'lodash';
import { GridFlex, HighlightText, Icon, LongText, Text } from '@stigg-components';
import { CouponFragment, CouponType, Currency, SubscriptionCouponDataFragment } from '@stigg-types/apiTypes';
import {
  DynamicDataSearchableOptions,
  DynamicSearchDataLoaderOutput,
} from '../../../../../../../../components/DynamicDataSearchableSelect';
import { useGetCoupons } from '../../../../../../../coupons/queries/useGetCoupons';
import { formatCoupon } from '../../../../../../../coupons/components/utils';
import { SEARCH_COMPONENT_HEIGHT } from './SubscriptionDiscountConfiguration';

type CouponObject = CouponFragment | SubscriptionCouponDataFragment;

const isCouponDisabled = (coupon: CouponObject, currency: Currency) =>
  coupon.type === CouponType.Fixed &&
  (coupon.amountsOff?.length ? !coupon.amountsOff?.some((c) => c.currency === currency) : currency !== Currency.Usd);

const sortDisabledCoupons = (a: CouponObject, b: CouponObject, currency: Currency) => {
  if (isCouponDisabled(a, currency) && !isCouponDisabled(b, currency)) return 1;
  if (!isCouponDisabled(a, currency) && isCouponDisabled(b, currency)) return -1;
  return 0;
};

export const useCouponOptions = (
  search: string,
  currency: Currency,
  existingSubscriptionCoupon: CouponObject | undefined,
): DynamicSearchDataLoaderOutput => {
  const { data, isLoading } = useGetCoupons(search);

  const coupons: DynamicDataSearchableOptions[] = useMemo(() => {
    if (!data || isEmpty(data.coupons)) return [];

    // hack to display coupons that exist on subscription only
    const isSubscriptionCouponExist = data.coupons.find((coupon) => coupon.refId === existingSubscriptionCoupon?.refId);
    const fetchedCoupons = compact([
      ...data.coupons,
      ...(isSubscriptionCouponExist ? [] : [existingSubscriptionCoupon]),
    ]).sort((a, b) => sortDisabledCoupons(a, b, currency));

    return fetchedCoupons.map((coupon) => ({
      id: coupon.refId,
      render: (search?: string, isDisabled?: boolean) => (
        <GridFlex.RowSpaceBetween
          alignItems="center"
          width="100%"
          height={SEARCH_COMPONENT_HEIGHT}
          minHeight={SEARCH_COMPONENT_HEIGHT}>
          <GridFlex.Row gap={2} alignItems="center">
            <Icon icon="Discount" color="default" overrideFill overrideStroke />
            <GridFlex.Column>
              <LongText variant="body2">
                <HighlightText
                  text={formatCoupon(coupon, currency, {
                    withoutValue: isDisabled,
                    showNegativeSign: false,
                    withCodePostfix: false,
                  })}
                  highlight={search}
                />
              </LongText>
              {isDisabled && (
                <Text.B2 noWrap fontSize="12px">
                  {t('subscriptionForm.discount.invalidCurrency')}
                </Text.B2>
              )}
            </GridFlex.Column>
          </GridFlex.Row>
          <Text.B2>
            <HighlightText text={coupon.refId} highlight={search} />
          </Text.B2>
        </GridFlex.RowSpaceBetween>
      ),
      renderSelected: () => (
        <GridFlex.Row gap={2} alignItems="center">
          <Icon icon="Discount" color="default" overrideFill overrideStroke />
          <Text.B2>{formatCoupon(coupon, currency, { showNegativeSign: false })}</Text.B2>
        </GridFlex.Row>
      ),
      isInSearch: (search: string) =>
        coupon.name.toLowerCase().includes(search.toLowerCase()) ||
        coupon.refId.toLowerCase().includes(search.toLowerCase()),
      isDisabled: isCouponDisabled(coupon, currency),
      onOptionSelected: ({ setFieldValue }) => {
        setFieldValue('couponRefId', coupon.refId);
        setFieldValue('coupon', coupon);
      },
    }));
  }, [currency, data, existingSubscriptionCoupon]);

  return { isLoading, data: coupons, totalItems: data?.totalCount };
};
