import { useCallback } from 'react';
import {
  AddonFragment,
  BillingCadence,
  BillingModel,
  BillingPeriod,
  Currency,
  DefaultTrialConfigInputDto,
  MinimumSpendInput,
  OveragePricingModelCreateInput,
  PlanFragment,
  PricePeriodInput,
  PriceTierInput,
  PricingModelCreateInput,
  PricingType,
  TiersMode as ApiTiersMode,
  TrialPeriodUnits,
} from '@stigg-types/apiTypes';
import { isEmpty, isNumber, pick } from 'lodash';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import {
  Charge,
  ChargeType,
  CountryPriceLocalization,
  OverageCharge,
  PriceLocalization,
  PriceTier,
  SetPriceWizardFormFields,
  TIERS_SCHEMA_PER_UNIT,
  TiersSchema,
  UsageBasedChargeType,
  MinimumSpend,
} from './SetPriceWizardForm.types';
import { setPackagePricingAction } from '../../../priceSlice';
import { AppDispatch, DispatchResponseOptional, useAppDispatch } from '../../../../../../redux/store';
import { SetPackagePricingInput } from '../../../mutations/setPackagePricing';
import { updatePlanAction } from '../../../../plans/plansSlice';
import { convertToBackendAmount } from './utils/priceConversions';
import { isTieredCharge } from './SetPriceWizardForm.utils';
import { ALL_BILLING_PERIODS } from './chargesStep/utils/getPricePeriodFields';
import { getResetPeriodData } from '../../../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements.utils';

const keysForSetPackagePricingAction: Array<keyof SetPriceWizardFormFields> = [
  'pricingType',
  'defaultCurrency',
  'billingPeriods',
  'billingCadence',
  'charges',
  'overageCharges',
  'overageBillingPeriod',
  'priceLocalization',
  'minimumSpend',
];

const keysForSetFreeTrialAction: Array<keyof SetPriceWizardFormFields> = ['freeTrial'];

type CommonChargeToLocalizedTierPricePointInputProps = {
  charge: Charge;
  billingCadence?: BillingCadence;
  billingPeriod: BillingPeriod;
  billingPeriods: BillingPeriod[];
  currency: CountryPriceLocalization['currency'];
  billingCountryCode: CountryPriceLocalization['billingCountryCode'];
};

function toPriceTierInput(
  tiersSchema: TiersSchema,
  tier: PriceTier,
  billingPeriod: BillingPeriod,
  billingPeriods: BillingPeriod[],
  currency?: Currency,
  upToOverride?: number | null,
): PriceTierInput {
  const result: PriceTierInput = {};

  const upTo = upToOverride ?? tier.endUnit;
  if (upTo) {
    result.upTo = upTo;
  }

  switch (tiersSchema) {
    case TiersSchema.GraduatedPerUnit:
    case TiersSchema.VolumePerUnit: {
      result.unitPrice = {
        amount: convertToBackendAmount(tier.unitPrice || 0, billingPeriod, billingPeriods),
        currency,
      };
      result.flatPrice = {
        amount: convertToBackendAmount(tier.tierPrice || 0, billingPeriod, billingPeriods),
        currency,
      };
      break;
    }
    case TiersSchema.VolumeBulkOfUnits: {
      result.flatPrice = {
        amount: convertToBackendAmount(tier.tierPrice || 0, billingPeriod, billingPeriods),
        currency,
      };
      break;
    }
    default: {
      break;
    }
  }

  return result;
}

function tieredChargeToLocalizedTierPricePointInput({
  charge,
  billingPeriod,
  billingPeriods,
  currency,
  billingCountryCode,
  chargesTieredPricePeriods,
}: CommonChargeToLocalizedTierPricePointInputProps & {
  charge: UsageBasedChargeType;
  chargesTieredPricePeriods: CountryPriceLocalization['chargesTieredPricePeriods'];
}) {
  const localizedTiers = chargesTieredPricePeriods[charge.uuid][billingPeriod];
  const chargeTiers = charge.tiers![billingPeriod];

  if (isNil(localizedTiers)) {
    return undefined;
  }

  const tiers = localizedTiers.map((tier, index) =>
    toPriceTierInput(charge.tiersSchema!, tier, billingPeriod, billingPeriods, currency, chargeTiers![index].endUnit),
  );

  return {
    billingPeriod,
    billingCountryCode,
    tiers,
  };
}

function chargeToLocalizedTierPricePointInput({
  charge,
  billingCadence,
  billingPeriod,
  billingPeriods,
  currency,
  billingCountryCode,
  chargesPricePeriods,
}: CommonChargeToLocalizedTierPricePointInputProps & {
  chargesPricePeriods: CountryPriceLocalization['chargesPricePeriods'];
}) {
  const isOneOff = billingCadence === BillingCadence.OneOff;
  const { amount, blockSize } =
    (isOneOff
      ? chargesPricePeriods[charge.uuid][BillingPeriod.Monthly]
      : chargesPricePeriods[charge.uuid][billingPeriod]) || {};
  if (isNil(amount)) {
    return undefined;
  }

  return {
    billingPeriod,
    billingCountryCode,
    blockSize,
    price: {
      amount: convertToBackendAmount(amount, billingPeriod, billingPeriods, isOneOff),
      currency,
    },
  };
}

function toLocalizedPricePointInput(
  charge: Charge,
  priceLocalization: PriceLocalization,
  billingPeriod: BillingPeriod,
  billingPeriods: BillingPeriod[],
  billingCadence: BillingCadence,
): PricePeriodInput[] {
  if (!priceLocalization.enabled) {
    return [];
  }

  return compact(
    priceLocalization.countries.map<PricePeriodInput | undefined>(
      ({ billingCountryCode, currency, chargesPricePeriods, chargesTieredPricePeriods }) => {
        if (isTieredCharge(charge) && chargesTieredPricePeriods) {
          return tieredChargeToLocalizedTierPricePointInput({
            charge: charge as UsageBasedChargeType,
            billingPeriod,
            billingPeriods,
            currency,
            billingCountryCode,
            chargesTieredPricePeriods,
          });
        }

        return chargeToLocalizedTierPricePointInput({
          charge,
          billingCadence,
          billingPeriod,
          billingPeriods,
          currency,
          billingCountryCode,
          chargesPricePeriods,
        });
      },
    ),
  );
}

function toPricePointInput(
  charge: Charge,
  defaultCurrency: Currency,
  priceLocalization: PriceLocalization,
  billingPeriod: BillingPeriod,
  billingPeriods: BillingPeriod[],
  billingCadence: BillingCadence,
): PricePeriodInput[] {
  const isUsageBased = charge.type === ChargeType.UsageBased;
  const isTiered = isUsageBased && ![TiersSchema.Standard, TiersSchema.StandardPerBlock].includes(charge.tiersSchema!);
  const isOneOff = billingCadence === BillingCadence.OneOff;

  const { amount, blockSize } =
    (isOneOff ? charge.pricePeriods[BillingPeriod.Monthly] : charge.pricePeriods[billingPeriod]) || {};
  const hasNoStandardPrice = !isTiered && isNil(amount);
  const hasNoTiersPrice = isTiered && isNil(charge.tiers);
  if (hasNoStandardPrice && hasNoTiersPrice) {
    return [];
  }

  const defaultPriceInput: PricePeriodInput = {
    billingPeriod,
    blockSize: charge.isBlockPricing ? blockSize : undefined,
  };
  if (isTiered) {
    defaultPriceInput.tiers = charge.tiers![billingPeriod]!.map((tier) =>
      toPriceTierInput(charge.tiersSchema!, tier, billingPeriod, billingPeriods, defaultCurrency),
    );
  } else {
    defaultPriceInput.price = {
      amount: convertToBackendAmount(amount!, billingPeriod, billingPeriods, isOneOff),
      currency: defaultCurrency,
    };
  }

  const localizedPriceInputs = toLocalizedPricePointInput(
    charge,
    priceLocalization,
    billingPeriod,
    billingPeriods,
    billingCadence,
  );

  return [defaultPriceInput, ...localizedPriceInputs];
}

function toTiersModeInput(tiersSchema: TiersSchema) {
  switch (tiersSchema) {
    case TiersSchema.VolumeBulkOfUnits:
    case TiersSchema.VolumePerUnit:
      return ApiTiersMode.Volume;
    case TiersSchema.GraduatedPerUnit:
      return ApiTiersMode.Graduated;
    default:
      return undefined;
  }
}

function toPricingModelCreateInput(
  charge: Charge,
  defaultCurrency: Currency,
  priceLocalization: PriceLocalization,
  billingPeriods: BillingPeriod[],
  billingCadence: BillingCadence,
): PricingModelCreateInput | undefined {
  let additionalFields: Partial<PricingModelCreateInput> = {};

  if (charge.type === ChargeType.UsageBased) {
    if (!charge.feature) {
      return undefined;
    }
    additionalFields.featureId = charge.feature.id;
    const isTieredCharge = charge.tiersSchema !== TiersSchema.Standard;

    if (charge.billingModel === BillingModel.PerUnit && !isTieredCharge) {
      if (charge.minUnitQuantity) {
        additionalFields.minUnitQuantity = charge.minUnitQuantity;
      }
      if (charge.maxUnitQuantity) {
        additionalFields.maxUnitQuantity = charge.maxUnitQuantity;
      }
    }
    if (charge.tiersSchema && charge.tiersSchema !== TiersSchema.Standard) {
      additionalFields.tiersMode = toTiersModeInput(charge.tiersSchema);
      if (TIERS_SCHEMA_PER_UNIT.includes(charge.tiersSchema)) {
        ALL_BILLING_PERIODS.forEach((billingPeriod) => {
          const tiers = charge.tiers?.[billingPeriod];
          // set last tier upTo to be null as the boundary is infinity
          tiers![tiers!.length - 1].endUnit = null;
        });
      }
    }

    additionalFields = {
      ...additionalFields,
      ...getResetPeriodData(charge.resetPeriod, charge.resetPeriodConfiguration),
    };
  }
  if (billingCadence === BillingCadence.OneOff) {
    billingPeriods = [BillingPeriod.Monthly, BillingPeriod.Annually];
  }

  return {
    billingModel: charge.billingModel,
    billingCadence,
    pricePeriods: billingPeriods.flatMap((billingPeriod) =>
      toPricePointInput(charge, defaultCurrency, priceLocalization, billingPeriod, billingPeriods, billingCadence),
    ),
    ...additionalFields,
  };
}

function toOveragePricingModelCreateInput(
  overageCharge: OverageCharge,
  charges: Charge[],
  defaultCurrency: Currency,
  billingPeriods: BillingPeriod[],
): OveragePricingModelCreateInput | undefined {
  if (!overageCharge.feature) {
    return undefined;
  }

  const isPartOfCharge = charges.some(
    (charge) => charge.type === ChargeType.UsageBased && charge.feature?.id === overageCharge.feature?.id,
  );

  let entitlement;
  if (!isPartOfCharge) {
    entitlement = {
      featureId: overageCharge.feature.id,
      usageLimit: overageCharge.entitlement?.usageLimit,
      ...getResetPeriodData(
        overageCharge.entitlement?.resetPeriod,
        overageCharge.entitlement?.resetPeriodConfiguration,
      ),
    };
  }

  return {
    billingModel: overageCharge.billingModel,
    billingCadence: BillingCadence.OneOff,
    pricePeriods: billingPeriods.map((billingPeriod) => {
      const { amount, blockSize } = overageCharge.pricePeriods[billingPeriod]!;
      return {
        billingPeriod,
        blockSize,
        price: {
          amount: amount!,
          currency: defaultCurrency,
        },
      };
    }),
    featureId: overageCharge.feature.id,
    entitlement,
  };
}

function toMinimumSpendModelCreateInput(
  minimumSpend: MinimumSpend,
  billingPeriods: BillingPeriod[],
  defaultCurrency: Currency,
): MinimumSpendInput[] | null {
  const minimums = Object.entries(minimumSpend.minimums)
    .filter(
      ([billingPeriod, amount]) =>
        billingPeriods.includes(billingPeriod as BillingPeriod) && isNumber(amount) && amount > 0,
    )
    .map(([billingPeriod, amount]) => ({
      billingPeriod: billingPeriod as BillingPeriod,
      minimum: {
        amount,
        currency: defaultCurrency,
      },
    }));

  return !isEmpty(minimums) ? minimums : null;
}

function dispatchSetPackagePricing(
  dispatch: AppDispatch,
  aPackage: PlanFragment | AddonFragment,
  pricingType: PricingType,
  values: SetPriceWizardFormFields,
  initialValues: SetPriceWizardFormFields,
) {
  // no need to update if no change
  if (isEqual(pick(values, keysForSetPackagePricingAction), pick(initialValues, keysForSetPackagePricingAction))) {
    return undefined;
  }

  const packageRefId =
    aPackage.type === 'Plan'
      ? { planRefId: aPackage.refId, versionNumber: aPackage.versionNumber }
      : { addonRefId: aPackage.refId, versionNumber: aPackage.versionNumber };

  const setPackagePricingPayload: SetPackagePricingInput = {
    packageId: aPackage.id,
    pricingType,
    ...packageRefId,
  };

  if (pricingType === PricingType.Paid) {
    const {
      charges,
      overageCharges,
      defaultCurrency,
      priceLocalization,
      billingPeriods,
      billingCadence,
      overageBillingPeriod,
      minimumSpend,
    } = values;

    setPackagePricingPayload.pricingModels = compact(
      charges.map((charge) =>
        toPricingModelCreateInput(charge, defaultCurrency, priceLocalization, billingPeriods, billingCadence),
      ),
    );

    if (overageCharges.enabled) {
      setPackagePricingPayload.overagePricingModels = compact(
        overageCharges.charges.map((overageCharge) =>
          toOveragePricingModelCreateInput(overageCharge, charges, defaultCurrency, billingPeriods),
        ),
      );

      setPackagePricingPayload.overageBillingPeriod = overageBillingPeriod;
    }

    if (minimumSpend.enabled && !isEmpty(Object.entries(minimumSpend.minimums))) {
      setPackagePricingPayload.minimumSpend = toMinimumSpendModelCreateInput(
        minimumSpend,
        billingPeriods,
        defaultCurrency,
      );
    } else {
      setPackagePricingPayload.minimumSpend = null;
    }
  }

  return dispatch(setPackagePricingAction({ ...setPackagePricingPayload, silentFetch: true }));
}

function dispatchUpdateFreeTrial(
  dispatch: AppDispatch,
  aPackage: PlanFragment | AddonFragment,
  pricingType: PricingType,
  values: SetPriceWizardFormFields,
  initialValues: SetPriceWizardFormFields,
) {
  if (aPackage.type !== 'Plan' || (pricingType !== PricingType.Paid && pricingType !== PricingType.Custom)) {
    return undefined;
  }

  // no need to update if no change
  if (isEqual(pick(values, keysForSetFreeTrialAction), pick(initialValues, keysForSetFreeTrialAction))) {
    return undefined;
  }

  let defaultTrialConfig: DefaultTrialConfigInputDto | null = null;

  if (values.freeTrial.enabled) {
    defaultTrialConfig = {
      duration: values.freeTrial.durationDays,
      units: TrialPeriodUnits.Day,
      budget: values.freeTrial.spendLimit
        ? {
            limit: values.freeTrial.spendLimit,
            hasSoftLimit: false,
          }
        : null,
      trialEndBehavior: values.freeTrial.trialEndBehavior,
    };
  }

  return dispatch(
    updatePlanAction({
      planRefId: aPackage.refId,
      updatePayload: {
        id: aPackage.id,
        defaultTrialConfig,
      },
      silentFetch: true,
    }),
  );
}

export function useSetPriceWizardFormOnSubmit(
  aPackage: PlanFragment | AddonFragment,
  initialValues: SetPriceWizardFormFields,
  onClose: () => void,
  setHasPublishValidationError: (hasError: boolean) => void,
) {
  const dispatch = useAppDispatch();

  return useCallback(
    async (values: SetPriceWizardFormFields) => {
      const { pricingType } = values;
      if (!pricingType) {
        return;
      }

      const res1: DispatchResponseOptional = await dispatchSetPackagePricing(
        dispatch,
        aPackage,
        pricingType,
        values,
        initialValues,
      );
      const res2: DispatchResponseOptional = await dispatchUpdateFreeTrial(
        dispatch,
        aPackage,
        pricingType,
        values,
        initialValues,
      );

      const hadError = [res1, res2].some((res) => res?.meta.requestStatus === 'rejected');
      if (hadError) {
        // error occurred, don't close the modal
        return;
      }

      setHasPublishValidationError(false);
      onClose();
    },
    [dispatch, aPackage, initialValues, setHasPublishValidationError, onClose],
  );
}
