import { gql } from '@apollo/client';
import { RESET_PERIOD_CONFIGURATION } from '../../../entitlements/queries/resetPeriodConfigurationFragment';
import { PACKAGE_CHANGES_FRAGMENT } from './packageChangesFragment';
import { PRICE_FRAGMENT } from './priceFragment';
import { OVERAGE_PRICE_FRAGMENT } from './overagePriceFragment';
import { MINIMUM_SPEND_FRAGMENT } from './minimumSpendFragment';

export const PLAN_FRAGMENT = gql`
  fragment PlanCompatiblePackageGroupFragment on PlanCompatiblePackageGroups {
    packageGroupId
    options {
      minItems
      freeItems
    }
    displayName
    addons {
      refId
    }
  }
  fragment PlanCompatibleAddonFragment on Addon {
    id
    description
    displayName
    refId
    status
    createdAt
    updatedAt
    prices {
      ...PriceFragment
    }
    overagePrices {
      ...OveragePriceFragment
    }
    pricingType
    maxQuantity
  }
  fragment PlanFragment on Plan {
    id
    refId
    displayName
    description
    billingId
    billingLinkUrl
    awsMarketplacePlanDimension
    status
    type
    createdAt
    updatedAt
    versionNumber
    hiddenFromWidgets
    isLatest
    syncStates {
      vendorIdentifier
      status
      error
    }
    additionalMetaData
    draftSummary {
      version
      updatedAt
      updatedBy
    }
    draftDetails {
      customersAffected
      childPlansWithDraft {
        refId
        displayName
        product {
          displayName
        }
      }
      affectedChildPlans {
        displayName
        product {
          displayName
        }
      }
      changes {
        ...PackageChangesFragment
      }
    }
    defaultTrialConfig {
      units
      duration
      budget {
        limit
      }
      trialEndBehavior
    }
    entitlements {
      ...PlanEntitlementFragment
    }
    inheritedEntitlements(includeOverridden: true) {
      ...PlanEntitlementFragment
    }
    product {
      id
      displayName
      refId
      multipleSubscriptions
      awsMarketplaceProductId
      awsMarketplaceProductCode
      productSettings {
        subscriptionEndSetup
        subscriptionCancellationTime
        downgradePlan {
          refId
          displayName
        }
        subscriptionStartSetup
        subscriptionStartPlan {
          id
          refId
          displayName
        }
      }
    }
    basePlan {
      id
      refId
      displayName
      isLatest
      versionNumber
      status
      overagePrices {
        ...OveragePriceFragment
      }
    }
    compatibleAddons {
      ...PlanCompatibleAddonFragment
    }
    compatiblePackageGroups {
      ...PlanCompatiblePackageGroupFragment
    }
    prices {
      ...PriceFragment
      resetPeriod
      resetPeriodConfiguration {
        __typename
        ... on YearlyResetPeriodConfig {
          ...YearlyResetPeriodConfigFragment
        }
        ... on MonthlyResetPeriodConfig {
          ...MonthlyResetPeriodConfigFragment
        }
        ... on WeeklyResetPeriodConfig {
          ...WeeklyResetPeriodConfigFragment
        }
      }
    }
    overagePrices {
      ...OveragePriceFragment
    }
    overageBillingPeriod
    pricingType
    minimumSpend {
      ...MinimumSpendFragment
    }
  }
  fragment PlanEntitlementFragment on PackageEntitlement {
    packageId
    id
    featureId
    usageLimit
    hasUnlimitedUsage
    hasSoftLimit
    resetPeriod
    hiddenFromWidgets
    displayNameOverride
    isCustom
    behavior
    resetPeriodConfiguration {
      __typename
      ... on YearlyResetPeriodConfig {
        ...YearlyResetPeriodConfigFragment
      }
      ... on MonthlyResetPeriodConfig {
        ...MonthlyResetPeriodConfigFragment
      }
      ... on WeeklyResetPeriodConfig {
        ...WeeklyResetPeriodConfigFragment
      }
    }
    environmentId
    feature {
      id
      displayName
      refId
      description
      featureStatus
      updatedAt
      environmentId
      featureType
      meterType
      featureUnits
      featureUnitsPlural
      hasMeter
    }
    createdAt
    order
  }
  ${PRICE_FRAGMENT}
  ${OVERAGE_PRICE_FRAGMENT}
  ${MINIMUM_SPEND_FRAGMENT}
  ${RESET_PERIOD_CONFIGURATION}
  ${PACKAGE_CHANGES_FRAGMENT}
`;
