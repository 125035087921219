import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { t } from 'i18next';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Edit2, Trash2 } from 'react-feather';
import {
  Box,
  Divider,
  IconButton,
  Text,
  OptionsDropdown,
  Chip,
  Grid,
  GridFlex,
  ExternalLink,
  Alert,
  PageCard,
  ConfirmationDialog,
} from '@stigg-components';
import { IntegrationFragment } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useBreadcrumbs } from '@stigg-common';
import { availableIntegrations, VendorIdentifier } from '../constants';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useNavigation } from '../../navigation/useNavigation';
import { ZuoraIntegrationForm, ZuoraIntegrationFormProps } from './zuora/ZuoraIntegrationForm';
import { AWSMarketplaceIntegrationPage } from './awsMarketPlace/AWSMarketplaceIntegrationPage';
import { deleteIntegrationByIdAction, fetchIntegrationByVendorAction } from '../integrationsSlice';
import Loader from '../../../components/Loader';
import { ZuoraIntegrationDetails, ZuoraIntegrationDetailsProps } from './zuora/ZuoraIntegrationDetails';
import { StripeIntegrationDetails, StripeIntegrationDetailsProps } from './StripeIntegrationDetails';
import { StripeConnect } from './StripeConnect';
import { IntegrationIcon } from './IntegrationCard';
import { StripeImport } from './stripeImport/StripeImport';
import { SnowflakeIntegrationPage } from './snowflake/SnowflakeIntegrationPage';
import { SalesforceIntegrationPage } from './salesforce/SalesforceIntegrationPage';
import { BigQueryIntegrationPage } from './bigQuery/BigQueryIntegrationPage';
import { OpenFGAIntegrationDetails, OpenFGAIntegrationDetailsProps } from './openFga/OpenFGAIntegrationDetails';
import { OpenFGAIntegrationForm, OpenFGAIntegrationFormProps } from './openFga/OpenFGAIntegrationForm';
import { HubSpotIntegrationPage } from './hubspotConnect/HubSpotIntegrationPage';
import { Auth0IntegrationPage } from './auth0/Auth0IntegrationPage';

const StyledEditIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const DetailsBox = styled(Box)`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export const StyledAlert = styled(Alert)<{ $muted: boolean }>`
  ${({ theme, $muted }) => {
    if ($muted) {
      return css`
        background-color: ${theme.itamar.palette.grey[25]};

        & .MuiAlert-icon {
          color: ${theme.itamar.palette.primary.main};
        }
      `;
    }
    return '';
  }}
`;

type IntegrationPageRouteParams = {
  vendorIdentifier: string;
};

type VendorComponents = {
  renderDetails?: (props: { integration: IntegrationFragment }) => JSX.Element;
  renderForm?: (props: { onCancel: () => void; integration?: IntegrationFragment | null }) => JSX.Element;
  renderOAuthProvider?: () => JSX.Element;
  renderFooterSection?: (props: { integration: IntegrationFragment | null }) => JSX.Element;
  renderPage?: (props: { integration: IntegrationFragment | null; headerComponent: React.ReactNode }) => JSX.Element;
};

const vendorsComponents: Record<VendorIdentifier, VendorComponents> = {
  [VendorIdentifier.Zuora]: {
    renderDetails: (props: ZuoraIntegrationDetailsProps) => <ZuoraIntegrationDetails {...props} />,
    renderForm: (props: ZuoraIntegrationFormProps) => <ZuoraIntegrationForm {...props} />,
  },
  [VendorIdentifier.Stripe]: {
    renderDetails: (props: StripeIntegrationDetailsProps) => <StripeIntegrationDetails {...props} />,
    renderOAuthProvider: () => <StripeConnect />,
    renderFooterSection: (props: { integration: IntegrationFragment | null }) => <StripeImport {...props} />,
  },
  [VendorIdentifier.AwsMarketplace]: {
    renderPage: ({ integration, headerComponent }) => (
      <AWSMarketplaceIntegrationPage integration={integration} headerComponent={headerComponent} />
    ),
  },
  [VendorIdentifier.Hubspot]: {
    renderPage: ({ integration, headerComponent }) => (
      <HubSpotIntegrationPage integration={integration} headerComponent={headerComponent} />
    ),
  },
  [VendorIdentifier.Salesforce]: {
    renderPage: ({ integration, headerComponent }) => (
      <SalesforceIntegrationPage integration={integration} headerComponent={headerComponent} />
    ),
  },
  [VendorIdentifier.Chargebee]: {},
  [VendorIdentifier.Slack]: {}, // handled saparetly as a specific route using webhoook forms
  [VendorIdentifier.Snowflake]: {
    renderPage: ({ integration, headerComponent }) => (
      <SnowflakeIntegrationPage integration={integration} headerComponent={headerComponent} />
    ),
  },
  [VendorIdentifier.BigQuery]: {
    renderPage: ({ integration, headerComponent }) => (
      <BigQueryIntegrationPage integration={integration} headerComponent={headerComponent} />
    ),
  },
  [VendorIdentifier.Auth0]: {
    renderPage: ({ integration, headerComponent }) => (
      <Auth0IntegrationPage integration={integration} headerComponent={headerComponent} />
    ),
  },
  [VendorIdentifier.OpenFGA]: {
    renderDetails: (props: OpenFGAIntegrationDetailsProps) => <OpenFGAIntegrationDetails {...props} />,
    renderForm: (props: OpenFGAIntegrationFormProps) => <OpenFGAIntegrationForm {...props} />,
  },
};

export function IntegrationPage() {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { slackIntegration: slackIntegrationEnabled } = useFlags<FeatureFlags>();

  const { vendorIdentifier: vendorIdentifierParam } = useParams<IntegrationPageRouteParams>();
  const vendorIdentifier = vendorIdentifierParam.toUpperCase() as VendorIdentifier;
  const availableIntegration = availableIntegrations({
    slackIntegrationEnabled,
  }).find((x) => x.vendorIdentifier === vendorIdentifier);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editedIntegration, setEditedIntegration] = useState<IntegrationFragment | null>(null);
  const integration = useSelector((state: RootState) => state.integrations.integration);
  const isLoading = useSelector((state: RootState) => state.integrations.isLoading);

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && integration) {
      await dispatch(deleteIntegrationByIdAction(integration.id));
    }
    setDeleteDialogOpen(false);
  };

  useBreadcrumbs(
    { to: '/integrations', title: t('breadCrumbs.integrations') },
    { title: availableIntegration?.name || '' },
  );

  useEffect(() => {
    void dispatch(fetchIntegrationByVendorAction({ vendorIdentifier }));
  }, [dispatch, vendorIdentifier]);

  if (!availableIntegration) {
    return <Redirect to={{ pathname: navigation.getEnvPath('/integrations') }} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  const { renderPage, renderForm, renderDetails, renderOAuthProvider, renderFooterSection } =
    vendorsComponents[availableIntegration.vendorIdentifier];

  const headerComponent = (
    <>
      <Grid container alignItems="center" columnSpacing={4} mb={4}>
        <Grid item display="flex" alignItems="center">
          <IntegrationIcon src={availableIntegration.icon} alt={availableIntegration.name} />
        </Grid>

        <GridFlex.Column item flex={1}>
          <Text.Sub2>{t('integrations.cardSubtitle')}</Text.Sub2>
          <Grid container alignItems="center">
            <Grid item alignItems="center">
              <Text.H3>{availableIntegration.name}</Text.H3>
              {availableIntegration.beta && <Text.B2 ml={2}>{t('integrations.beta')}</Text.B2>}
            </Grid>
            {!!integration && (
              <Grid item ml={4}>
                <Chip size="small" label="Active" color="primary" />
              </Grid>
            )}
          </Grid>
        </GridFlex.Column>

        <Grid item display="flex" alignItems="center">
          <Grid item>
            {!!integration && (
              <OptionsDropdown
                options={[
                  {
                    text: 'Remove',
                    icon: Trash2,
                    onClick: () => {
                      setAddDialogOpen(false);
                      setEditedIntegration(null);
                      setDeleteDialogOpen(true);
                    },
                  },
                ]}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <GridFlex.Column item $fullWidth rowSpacing={4}>
        <Box>
          <Text.B2 color="secondary" maxWidth={650}>
            {availableIntegration.getDescription()}
          </Text.B2>
        </Box>

        {availableIntegration.docsUrl && (
          <Grid mt={4}>
            <StyledAlert $muted severity="info" sx={{ width: '100%' }}>
              <Text.B2 gap={1}>
                {t('integrations.integrationGuidePart1')}
                <ExternalLink
                  label={t('integrations.integrationGuidePart2', { integrationName: availableIntegration.name })}
                  url={availableIntegration.docsUrl}
                />
              </Text.B2>
            </StyledAlert>
          </Grid>
        )}
      </GridFlex.Column>
    </>
  );

  let pageContent: React.ReactNode;

  if (renderPage) {
    pageContent = renderPage({ headerComponent, integration });
  } else {
    pageContent = (
      <>
        <PageCard cardProps={{ p: 4 }}>
          {headerComponent}

          {integration && !addDialogOpen && renderDetails && (
            <>
              <Divider my={4} />
              <DetailsBox p={4} position="relative">
                {renderForm && (
                  <StyledEditIconButton
                    onClick={() => {
                      setEditedIntegration(integration);
                      setAddDialogOpen(true);
                    }}>
                    <Edit2 size={20} color="rgba(0, 0, 0, 0.26)" />
                  </StyledEditIconButton>
                )}

                {renderDetails({ integration })}
              </DetailsBox>
            </>
          )}

          {!integration && renderOAuthProvider && (
            <>
              <Divider my={4} />
              {renderOAuthProvider()}
            </>
          )}

          {(!integration || addDialogOpen) && renderForm && (
            <>
              <Divider my={4} />
              {renderForm({ integration: editedIntegration, onCancel: () => setAddDialogOpen(false) })}
            </>
          )}
        </PageCard>

        {renderFooterSection && renderFooterSection({ integration })}
      </>
    );
  }

  return (
    <>
      <Grid container flexWrap="nowrap" flexDirection="column" rowSpacing={6}>
        <Grid flexDirection="column" container item alignSelf="center" justifyContent="center" sx={{ maxWidth: 800 }}>
          {pageContent}
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('integrations.delete')}
        content={t('integrations.deleteConfirmation')}
      />
    </>
  );
}
