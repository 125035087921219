import {
  Currency,
  DiscountType,
  SubscriptionMaximumSpendDiscount,
  SubscriptionPreviewDiscount,
} from '@stigg-types/apiTypes';
import { currencyPriceFormatter } from '../../../packages/pricing/components/currency/currencyUtils';

export const formatDiscountValue = (
  discountValue: number,
  discountType: DiscountType,
  currency: Currency | undefined = Currency.Usd,
) => {
  if (discountType === DiscountType.Percentage) {
    return `${discountValue}% off`;
  }

  if (discountType === DiscountType.Fixed) {
    return `${currencyPriceFormatter({ amount: discountValue, currency, options: { withCodePostfix: true } })} off`;
  }
  return '';
};

export const formatDiscountDetails = (
  { name, value, type }: SubscriptionPreviewDiscount | SubscriptionMaximumSpendDiscount,
  currency: Currency,
) => {
  if (!value) {
    return name || '';
  }

  return `${name} (${formatDiscountValue(value, type || DiscountType.Fixed, currency)})`;
};
