import React, { useCallback } from 'react';
import {
  BillingAnchor,
  CustomerSubscriptionDataFragment,
  PricingType,
  SubscriptionCancellationTime,
  SubscriptionDataFragment,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Grid, LocalDate, Text } from '@stigg-components';
import { capitalize, first, includes } from 'lodash';
import { downgradeProductTimeFormat } from '@stigg-common';
import { Form, SelectFields } from '../../../../../components/form';
import { cancelSubscriptionAction } from '../../../customersSlice';
import { CancelSubscriptionInput } from '../../../mutations/cancelSubscription';
import { calcSubscriptionEndOfBillingCycle } from './calcEndOfBillingCycle';
import { RootState, useAppDispatch } from '../../../../../redux/store';

const getAllowedCancellationTimes = (
  subscription?: CustomerSubscriptionDataFragment | SubscriptionDataFragment | null,
  defaultCancelAction?: SubscriptionCancellationTime,
  subscriptionBillingAnchor?: BillingAnchor | null,
) => {
  const cancellationTimes: SelectFields['values'] = [];

  const subscriptionPrice = first(subscription?.prices);
  const subscriptionPendingPayment = subscription?.status === SubscriptionStatus.PaymentPending;
  const isCustom = subscription?.pricingType === PricingType.Custom;
  const isTrial = subscription?.status === SubscriptionStatus.InTrial;
  const isFree = subscription?.pricingType === PricingType.Free;
  const isNotGranted = isCustom || isFree || isTrial;

  if ((!!subscriptionPrice || !!subscription?.endDate) && !subscriptionPendingPayment && !isCustom) {
    cancellationTimes.push({
      value: SubscriptionCancellationTime.EndOfBillingPeriod,
      displayValue: capitalize(
        downgradeProductTimeFormat(SubscriptionCancellationTime.EndOfBillingPeriod, isTrial, isNotGranted),
      ),
      subtitle:
        defaultCancelAction === SubscriptionCancellationTime.EndOfBillingPeriod ? t('subscriptions.defaultSetup') : '',
      subtitlePosition: 'right',
      overrideDisplayValue: (
        <Grid container>
          <Text.B2>
            {capitalize(
              downgradeProductTimeFormat(SubscriptionCancellationTime.EndOfBillingPeriod, isTrial, isNotGranted),
            )}
          </Text.B2>
          <Text.Sub2 paddingLeft={2}>
            <LocalDate
              date={calcSubscriptionEndOfBillingCycle(subscription, subscriptionBillingAnchor)}
              formatOptions={{ withTime: true }}
            />
          </Text.Sub2>
        </Grid>
      ),
    });
  }
  if (!subscriptionPendingPayment) {
    cancellationTimes.push({
      value: 'LATER_DATE',
      displayValue: t('subscriptions.laterDate'),
    });
  }

  cancellationTimes.push({
    value: SubscriptionCancellationTime.Immediate,
    displayValue: capitalize(downgradeProductTimeFormat(SubscriptionCancellationTime.Immediate, isTrial, isNotGranted)),
    subtitle: defaultCancelAction === SubscriptionCancellationTime.Immediate ? t('subscriptions.defaultSetup') : '',
    subtitlePosition: 'right',
  });

  return cancellationTimes;
};

export type CancelSubscriptionFormProps = {
  subscription?: CustomerSubscriptionDataFragment | SubscriptionDataFragment | null;
  customerId: string;
  onSubscriptionCancel?: () => Promise<unknown>;
  onClose: () => void;
};

export const CancelSubscriptionForm = ({
  subscription,
  onClose,
  customerId,
  onSubscriptionCancel,
}: CancelSubscriptionFormProps) => {
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const dispatch = useAppDispatch();
  const defaultCancelAction = subscription?.plan.product.productSettings.subscriptionCancellationTime;

  const validationSchema = Yup.object().shape({
    endDate: Yup.date().when('subscriptionCancelTime', {
      is: 'LATER_DATE',
      then: Yup.date().required(t('products.yup.required')),
      otherwise: Yup.date().notRequired().nullable(true),
    }),
  });

  const handleSubmit = useCallback(
    async (values: any) => {
      const { endDate } = values;
      const data: CancelSubscriptionInput = {
        customerId,
        subscriptionRefId: subscription!.subscriptionId,
      };
      switch (values.subscriptionCancelTime) {
        case SubscriptionCancellationTime.EndOfBillingPeriod:
          data.subscriptionCancellationTime = SubscriptionCancellationTime.EndOfBillingPeriod;
          break;
        case SubscriptionCancellationTime.Immediate:
          data.subscriptionCancellationTime = SubscriptionCancellationTime.Immediate;
          break;
        case 'LATER_DATE':
          data.subscriptionCancellationTime = SubscriptionCancellationTime.SpecificDate;
          data.endDate = endDate;
          break;
        default:
      }
      await dispatch(cancelSubscriptionAction(data));
      await onSubscriptionCancel?.();
      onClose();
    },
    [dispatch, customerId, subscription, onClose, onSubscriptionCancel],
  );
  const cancellationTimes = getAllowedCancellationTimes(
    subscription,
    defaultCancelAction,
    account?.subscriptionBillingAnchor,
  );
  const hasDefaultCancelAction = includes(
    cancellationTimes.map((x) => x.value),
    defaultCancelAction,
  );

  const initialValues = {
    endDate: null,
    subscriptionCancelTime:
      defaultCancelAction && hasDefaultCancelAction ? defaultCancelAction : first(cancellationTimes)?.value,
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitButtonText={t('subscriptions.cancelSubscriptionCTA')}
      cancelButtonText={t('sharedComponents.dismiss')}
      saveButtonColor="error"
      validationSchema={validationSchema}
      fields={[
        {
          type: 'select',
          id: 'subscriptionCancelTime',
          values: cancellationTimes,
        },
        {
          type: 'datePicker',
          hide: (values: any) => values.subscriptionCancelTime !== 'LATER_DATE',
          id: 'endDate',
        },
      ]}
      onCancel={onClose}
    />
  );
};
