import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { isNumber } from 'lodash';
import { GridFlex, Button, drawFormFields, Icon, IconButton } from '@stigg-components';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { DEFAULT_CURRENCY, getCurrencySymbol } from '../../../currency/currencyUtils';

function AddTrialSpendLimit({ onClick }: { onClick: () => void }) {
  return (
    <GridFlex.Row maxWidth={160}>
      <Button variant="outlined" startIcon={<Icon icon="Plus" />} onClick={onClick} fullWidth>
        {t('pricing.trials.addSpendLimit')}
      </Button>
    </GridFlex.Row>
  );
}

function RemoveTrialSpendLimit({ onClick }: { onClick: () => void }) {
  return (
    <IconButton onClick={onClick}>
      <Icon icon="Close" />
    </IconButton>
  );
}

export function TrialSpendLimit() {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const {
    values: { freeTrial },
  } = formRenderProps;
  const [isSpendingLimitEnabled, setIsSpendingLimitEnabled] = useState(isNumber(freeTrial.spendLimit));

  const handleAddSpendLimit = () => {
    setIsSpendingLimitEnabled(true);
  };

  const handleRemoveSpendLimit = () => {
    setIsSpendingLimitEnabled(false);
    formRenderProps.setFieldValue('freeTrial.spendLimit', null);
  };

  const drawField = useCallback(
    () =>
      drawFormFields(
        [
          {
            type: 'text',
            id: 'freeTrial.spendLimit',
            textFieldType: 'number',
            hide: () => !isSpendingLimitEnabled,
            placeholder: t('pricing.trials.trialSpendLimitPlaceholder'),
            isNumberWithoutSigns: true,
            isNumberWithoutFraction: true,
            endAdornment: `${getCurrencySymbol(DEFAULT_CURRENCY)} ${DEFAULT_CURRENCY}`,
            gridProps: {
              width: 260,
            },
          },
        ],
        formRenderProps,
      ),
    [formRenderProps, isSpendingLimitEnabled],
  );

  return isSpendingLimitEnabled ? (
    <GridFlex.Row gap={1}>
      <GridFlex.Item maxWidth={260}>{drawField()}</GridFlex.Item>
      <RemoveTrialSpendLimit onClick={handleRemoveSpendLimit} />
    </GridFlex.Row>
  ) : (
    <AddTrialSpendLimit onClick={handleAddSpendLimit} />
  );
}
