import { Icon, InformationalTooltipIcon } from '@stigg-components';
import React from 'react';
import { t } from 'i18next';
import { StatusIconContainer } from './WizardAccordion.style';

export type StepStatusIconProps = {
  expanded: boolean;
  disabled: boolean;
  currentIndex: number;
  index: number;
  hasErrors?: boolean;
};

export function StepStatusIcon({ expanded, disabled, currentIndex, index, hasErrors }: StepStatusIconProps) {
  if (disabled) {
    return null;
  }

  if (currentIndex < 0 || index >= currentIndex) {
    return null;
  }

  if (hasErrors) {
    // don't show errors when it's expanded, as
    // they will be displayed in the step itself
    if (expanded) {
      return null;
    }

    return (
      <StatusIconContainer>
        <InformationalTooltipIcon
          icon={<Icon icon="Error" color="error" type="materialIcons" size={20} />}
          text={t('sharedComponents.errorsRequiresAttention')}
          placement="left"
        />
      </StatusIconContainer>
    );
  }

  return (
    <StatusIconContainer>
      <Icon icon="CheckCircle" color="success" size={24} />
    </StatusIconContainer>
  );
}
