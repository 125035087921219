import { t } from 'i18next';
import styled from 'styled-components/macro';
import { Alert, Button, Icon, Text, GridFlex } from '@stigg-components';
import { Clock } from 'react-feather';
import {
  SubscriptionDataFragment,
  BillingAnchor,
  SubscriptionStatus,
  SubscriptionScheduleStatus,
} from '@stigg-types/apiTypes';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import { calcSubscriptionEndOfBillingCycle } from '../../../customerPage/customerSubscriptions/calcEndOfBillingCycle';
import { formatDate } from '../../../../../common/formatDate';

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    flex: 1;
  }
`;

export function ScheduledToCancellation({
  subscription,
  subscriptionBillingAnchor,
  onCancelSubscriptionFutureUpdatesClick,
}: {
  subscription: SubscriptionDataFragment;
  subscriptionBillingAnchor?: BillingAnchor | null;
  onCancelSubscriptionFutureUpdatesClick?: (futureUpdateStatus: ActiveFutureUpdateStatus) => void;
}) {
  const isTrial = subscription.status === SubscriptionStatus.InTrial;
  const endOfBillingCycle = calcSubscriptionEndOfBillingCycle(subscription, subscriptionBillingAnchor);
  const { cancellationDate } = subscription;
  const wasCanceledAtEndOfBillingPeriod =
    new Date(endOfBillingCycle).toISOString() === new Date(cancellationDate).toISOString();

  const cancelUpdatesButton = onCancelSubscriptionFutureUpdatesClick ? (
    <Button
      $outlined
      color="warning"
      onClick={() => onCancelSubscriptionFutureUpdatesClick(SubscriptionScheduleStatus.Scheduled)}>
      <Icon icon="Trash2" color="warning" type="reactFeather" size={20} />
      <Text.B2 ml={2} color="warning">
        {t('subscriptions.schedules.cancelUpdate')}
      </Text.B2>
    </Button>
  ) : null;

  return (
    <StyledAlert severity="warning" icon={<Clock size={20} />} sx={{ alignItems: 'center', width: '100%' }}>
      <GridFlex.RowSpaceBetween container>
        <GridFlex.Column item>
          {t('subscriptions.subscriptionScheduledToCancellation')}
          {wasCanceledAtEndOfBillingPeriod
            ? t(isTrial ? 'subscriptions.cancellationWillTakeTrial' : 'subscriptions.cancellationWillTake', {
                formatDate: formatDate(cancellationDate),
              })
            : t('subscriptions.cancellationWillTakePlaceOn', { formatDate: formatDate(cancellationDate) })}
        </GridFlex.Column>
        {cancelUpdatesButton ? <GridFlex.Column item>{cancelUpdatesButton}</GridFlex.Column> : null}
      </GridFlex.RowSpaceBetween>
    </StyledAlert>
  );
}
