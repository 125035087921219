import { t } from 'i18next';
import { includes, some } from 'lodash';
import {
  BillingModel,
  Currency,
  Customer,
  PricingType,
  SubscriptionDataFragment,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { SubscriptionType } from '@stigg-common/types';
import { DEFAULT_CURRENCY } from '../packages/pricing/components/currency/currencyUtils';

export const getSubscriptionCurrency = (subscription: SubscriptionDataFragment, customer?: Customer): Currency => {
  const tierPrice = subscription?.prices?.[0]?.price?.tiers?.[0];

  const currentCurrency =
    subscription?.prices?.[0]?.price?.price?.currency ||
    tierPrice?.flatPrice?.currency ||
    tierPrice?.unitPrice?.currency ||
    customer?.billingCurrency ||
    DEFAULT_CURRENCY;

  return currentCurrency;
};

export const canCancelSubscription = (subscriptionStatus: SubscriptionStatus) =>
  includes(
    [
      SubscriptionStatus.InTrial,
      SubscriptionStatus.Active,
      SubscriptionStatus.NotStarted,
      SubscriptionStatus.PaymentPending,
    ],
    subscriptionStatus,
  );

export const isActiveSubscriptionUtil = (subscriptionStatus: SubscriptionStatus) =>
  includes([SubscriptionStatus.Active, SubscriptionStatus.InTrial, SubscriptionStatus.NotStarted], subscriptionStatus);

export const checkOutdatedPrices = (subscription: SubscriptionType): boolean => {
  if ([PricingType.Free, PricingType.Custom].includes(subscription.pricingType)) {
    return false;
  }

  const { outdatedPricePackages } = subscription;
  const subscriptionAddonsRefIds = subscription.addons?.map((subscriptionAddon) => subscriptionAddon.addon.refId);
  const hasOutdatedAddonPrices = some(
    subscriptionAddonsRefIds,
    (addonRefId) => addonRefId && includes(outdatedPricePackages, addonRefId),
  );
  const planPriceOutdated = includes(outdatedPricePackages, subscription?.plan?.refId);

  return planPriceOutdated || hasOutdatedAddonPrices;
};

export const checkOutdatedPackageVersion = (subscription: SubscriptionType) => {
  const hasOutdatedPlan = !subscription?.plan?.isLatest;
  const hasOutdatedAddons = !!subscription?.addons?.some((addon) => !addon.addon?.isLatest);

  return {
    hasOutdatedPlan,
    hasOutdatedAddons,
  };
};

export const hasOutdatedPackagesUtil = (subscription: SubscriptionType) => {
  const hasOutdatedPrice = checkOutdatedPrices(subscription);
  const { hasOutdatedPlan, hasOutdatedAddons } = checkOutdatedPackageVersion(subscription);

  let outdatedPackageDescription =
    hasOutdatedPlan && hasOutdatedAddons
      ? t('subscriptions.outdatedPlanAndAddon')
      : hasOutdatedPlan
      ? t('subscriptions.outdatedPlan')
      : t('subscriptions.outdatedAddon');

  const onlyPriceIsOutdated = !hasOutdatedPlan && !hasOutdatedAddons && hasOutdatedPrice;
  if (onlyPriceIsOutdated) {
    outdatedPackageDescription = t('subscriptions.outdatedPrice');
  }
  return {
    outdatedPackageDescription,
    hasOutdatedPackages: hasOutdatedPlan || hasOutdatedAddons,
    hasOutdatedPlan,
    hasOutdatedAddons,
    hasOutdatedPrice,
  };
};

export function hasPayAsYouGoPrice(subscription: SubscriptionType): boolean {
  return !!subscription.prices?.some(({ billingModel }) => billingModel === BillingModel.UsageBased);
}
