import { TrialPeriodUnits } from '@stigg-types/apiTypes';
import { Grid, GridFlex, Icon, TextField } from '@stigg-components';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { calculateTrialEndDate } from '@stigg-common';
import moment from 'moment/moment';

type TrialDurationProps = {
  isDisabled: boolean;
  duration: number;
  handleEndDateChange: (value: Date) => void;
  endDateUnits: TrialPeriodUnits;
  handleDurationChange: (value: number) => void;
  startDate: string;
};

export function TrialDuration({
  isDisabled,
  duration,
  handleDurationChange,
  endDateUnits,
  handleEndDateChange,
  startDate,
}: TrialDurationProps) {
  return (
    <GridFlex.RowCenter container gap={4}>
      <Grid item width={260}>
        <TextField
          disabled={isDisabled}
          type="number"
          value={duration}
          onChange={(e) => handleDurationChange(Number(e.target.value))}
          touched={false}
          error={false}
          endAdornment="days"
          InputProps={{ inputProps: { min: 0 } }}
        />
      </Grid>
      <Icon icon="TwoSidedArrows" />
      <Grid item width={174}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disabled={isDisabled}
            value={calculateTrialEndDate(startDate.toString(), duration, endDateUnits)}
            InputAdornmentProps={{ sx: { pr: 2 } }}
            inputFormat="DD/MM/YYYY"
            onChange={(value: any) => handleEndDateChange(value.toDate())}
            PaperProps={{ sx: { boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow } }}
            renderInput={(params) => <TextField name="datePick" error={false} touched={false} {...params} />}
            minDate={moment(startDate).add(0, 'day')}
            components={{
              OpenPickerIcon: () => (
                <Icon icon="DateRange" type="materialIcons" color={isDisabled ? 'disabled' : 'active'} />
              ),
            }}
          />
        </LocalizationProvider>
      </Grid>
    </GridFlex.RowCenter>
  );
}
