import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  UpdateOneCustomerMutation,
  UpdateOneCustomerMutationVariables,
  Customer,
  UpdateCustomerInput,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchCustomerByRefIdAction } from '../customersSlice';

const UPDATE_CUSTOMER = gql`
  mutation UpdateOneCustomer($input: UpdateCustomerInput!) {
    updateOneCustomer(input: $input) {
      id
      name
      email
      billingId
      billingLinkUrl
      updatedAt
      additionalMetaData
      defaultPaymentMethodId
      defaultPaymentMethodType
      defaultPaymentMethodLast4Digits
      crmHubspotCompanyId
      crmHubspotCompanyUrl
      syncStates {
        status
        vendorIdentifier
        error
      }
    }
  }
`;

async function updateCustomer(
  updatePayload: Omit<UpdateCustomerInput, 'refId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Customer> | undefined | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const response = await apolloClient.mutate<UpdateOneCustomerMutation, UpdateOneCustomerMutationVariables>({
        mutation: UPDATE_CUSTOMER,
        variables: {
          input: {
            ...updatePayload,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      await dispatch(fetchCustomerByRefIdAction({ customerId: updatePayload.customerId!, isSilentLoading: true }));
      return response.data?.updateOneCustomer;
    },
    {
      successMessage: t('customers.api.successUpdate'),
      failureMessageHandler: () => t('customers.api.failUpdate'),
    },
    dispatch,
  );
}

export { updateCustomer };
