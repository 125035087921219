import { FormRenderProps } from '@stigg-components';
import { TrialEndBehavior } from '@stigg-types/apiTypes';
import React from 'react';
import { t } from 'i18next';
import { SubscriptionFormFields } from '../subscriptionForm/SubscriptionForm.types';
import { AdditionalInputLayout, switchToCreateModeTooltip } from '../subscriptionForm/components';

export type TrialEndBehaviorConfigProps = {
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
  isUpdatingSubscription: boolean;
  switchToCreateMode: () => void;
};

export function TrialEndBehaviorConfig({
  formRenderProps,
  isUpdatingSubscription,
  switchToCreateMode,
}: TrialEndBehaviorConfigProps) {
  const disableTooltip = isUpdatingSubscription
    ? switchToCreateModeTooltip(
        t('subscriptionForm.freeTrial.trialEndBehavior.switchToCreateModeForTrialEndBehavior'),
        switchToCreateMode,
      )
    : undefined;
  return (
    <AdditionalInputLayout
      isSet
      setLabel={t('subscriptionForm.freeTrial.trialEndBehavior.label')}
      formRenderProps={formRenderProps}
      fields={[
        {
          id: 'trialEndBehavior',
          type: 'select',
          disabled: isUpdatingSubscription, // backend does not support yet editing for existing subscription
          values: [TrialEndBehavior.CancelSubscription, TrialEndBehavior.ConvertToPaid].map((value) => ({
            key: value,
            value,
            displayValue: t(`subscriptionForm.freeTrial.trialEndBehavior.options.${value}`),
          })),
          tooltip: disableTooltip,
          restProps: {
            width: 260,
          },
        },
      ]}
    />
  );
}
