import { useMemo } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Alert, ExternalLink, FormRenderProps, GridFlex } from '@stigg-components';
import { Auth0ApplicationType, SubscriptionStartSetup } from '@stigg-types/apiTypes';
import { SubscriptionStartSetupConfiguration } from '../../../products/components/productPage/customerJourney/SubscriptionStartSetupConfiguration';
import { Auth0IntegrationFormFields } from './useAuth0Form';
import { RootState } from '../../../../redux/store';

type Auth0InitialCustomerAccessProps = {
  individualShowMissingMAUEntitlement: boolean | undefined;
  organizationShowMissingMAUEntitlement: boolean | undefined;
  formRenderProps: FormRenderProps<Auth0IntegrationFormFields>;
  hasIntegration: boolean;
};

function MauFeatureAlert() {
  return (
    <Alert severity="error">
      {t('integrations.auth0.steps.initialCustomerAccess.mauFeatureNotIncludedInPlan')}{' '}
      <ExternalLink
        label={t('sharedComponents.learnMore')}
        url={t('integrations.auth0.steps.clientSetup.installationUrl')}
      />
    </Alert>
  );
}

export function Auth0InitialCustomerAccess({
  formRenderProps,
  hasIntegration,
  individualShowMissingMAUEntitlement,
  organizationShowMissingMAUEntitlement,
}: Auth0InitialCustomerAccessProps) {
  const products = useSelector((root: RootState) => root.productReducer.products);
  const allPlans = useMemo(() => products.flatMap((product) => product.plans), [products]);
  const { values } = formRenderProps;
  const showIndividual = [Auth0ApplicationType.Both, Auth0ApplicationType.Individual].includes(values.applicationType);
  const showOrganization = [Auth0ApplicationType.Both, Auth0ApplicationType.Organization].includes(
    values.applicationType,
  );

  return (
    <GridFlex.Column rowGap={4}>
      {showIndividual ? (
        <SubscriptionStartSetupConfiguration
          formRenderProps={formRenderProps}
          readonly={hasIntegration}
          readonlyHideDottedText={hasIntegration}
          productPlans={allPlans}
          showTrialPeriod={false}
          excludedStartSetup={[SubscriptionStartSetup.PlanSelection]}
          fieldNamePrefix="individual"
          label={
            values.applicationType === Auth0ApplicationType.Both
              ? t('integrations.auth0.steps.initialCustomerAccess.individualLabel')
              : undefined
          }
        />
      ) : null}

      {showIndividual && individualShowMissingMAUEntitlement && <MauFeatureAlert />}

      {showOrganization ? (
        <SubscriptionStartSetupConfiguration
          formRenderProps={formRenderProps}
          readonly={hasIntegration}
          readonlyHideDottedText={hasIntegration}
          productPlans={allPlans}
          showTrialPeriod={false}
          excludedStartSetup={[SubscriptionStartSetup.PlanSelection]}
          fieldNamePrefix="organization"
          label={
            values.applicationType === Auth0ApplicationType.Both
              ? t('integrations.auth0.steps.initialCustomerAccess.organizationLabel')
              : undefined
          }
        />
      ) : null}

      {showOrganization && organizationShowMissingMAUEntitlement && <MauFeatureAlert />}
    </GridFlex.Column>
  );
}
