import { useMemo, useEffect } from 'react';
import {
  Auth0ApplicationType,
  IntegrationFragment,
  Auth0CredentialsFragment,
  VendorIdentifier,
  SubscriptionStartSetup,
  Auth0CredentialsInput,
} from '@stigg-types/apiTypes';
import { WizardAccordion, PageCard, Text } from '@stigg-components';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { useAuth0IntegrationWizardSteps } from './useAuth0IntegrationWizardSteps';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchProductsAction } from '../../../products/productsSlice';
import { Auth0IntegrationFormFields } from './useAuth0Form';
import { createIntegrationAction, updateIntegrationAction } from '../../integrationsSlice';

type Auth0IntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function Auth0IntegrationPage({ integration, headerComponent }: Auth0IntegrationPageProps) {
  const dispatch = useAppDispatch();
  const hasIntegration = !!integration?.credentials;
  const credentials = integration?.credentials as Auth0CredentialsFragment;
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const products = useSelector((root: RootState) => root.productReducer.products);
  const allPlans = useMemo(() => products.flatMap((product) => product.plans), [products]);

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchProductsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  const steps = useAuth0IntegrationWizardSteps({
    hasIntegration,
    credentials: credentials || undefined,
    onAddIntegration: async (values: Auth0IntegrationFormFields) => {
      const { individual, organization, applicationType } = values;
      const individualInitialPlanId =
        individual.subscriptionStartSetup === SubscriptionStartSetup.FreePlan
          ? individual.subscriptionStartFreePlanId
          : individual.subscriptionStartTrialPlanId;
      const organizationInitialPlanId =
        organization.subscriptionStartSetup === SubscriptionStartSetup.FreePlan
          ? organization.subscriptionStartFreePlanId
          : organization.subscriptionStartTrialPlanId;
      const individualInitialPlanRefId = allPlans.find((plan) => plan.id === individualInitialPlanId)?.refId;
      const organizationInitialPlanRefId = allPlans.find((plan) => plan.id === organizationInitialPlanId)?.refId;

      const isIndividualApp = [Auth0ApplicationType.Individual, Auth0ApplicationType.Both].includes(applicationType);
      const isOrganizationApp = [Auth0ApplicationType.Individual, Auth0ApplicationType.Organization].includes(
        applicationType,
      );
      const auth0Credentials: Auth0CredentialsInput = {
        clientId: values.clientId,
        clientSecret: values.clientSecret,
        clientDomain: values.clientDomain,
        applicationId: values.applicationId,
        applicationType: values.applicationType,
        applicationName: values.applicationName,
        individualInitialPlanId: isIndividualApp ? individualInitialPlanRefId : undefined,
        individualSubscriptionStartSetup: isIndividualApp ? individual.subscriptionStartSetup : undefined,
        organizationInitialPlanId: isOrganizationApp ? organizationInitialPlanRefId : undefined,
        organizationSubscriptionStartSetup: isOrganizationApp ? organization.subscriptionStartSetup : undefined,
      };

      if (integration) {
        await dispatch(
          updateIntegrationAction({
            integrationId: integration.id,
            integrationData: {
              vendorIdentifier: VendorIdentifier.Auth0,
              auth0Credentials,
            },
          }),
        ).unwrap();
      } else {
        await dispatch(
          createIntegrationAction({
            vendorIdentifier: VendorIdentifier.Auth0,
            auth0Credentials,
          }),
        ).unwrap();
      }
    },
  });

  return (
    <>
      <StyledPageCard cardProps={{ p: 4, marginBottom: 6 }}>{headerComponent}</StyledPageCard>

      <Text.H6 mb={4}>{t('integrations.auth0.integrateWithStiggTitle')}</Text.H6>

      <WizardAccordion
        layoutProps={{ maxWidth: '100%' }}
        uniqueFlowId="auth0-stigg"
        steps={steps}
        isStepByStep={!hasIntegration}
        showContinueButtonForLastStep
      />
    </>
  );
}
