import { t } from 'i18next';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { Currency, CustomerResponseFragment } from '@stigg-types/apiTypes';
import { Box, Field, FormRenderProps, GridFlex, InformationTooltip, Text } from '@stigg-components';
import { AdditionalInputActionParams, AdditionalInputLayout } from '../index';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { formatCoupon } from '../../../../../../../coupons/components/utils';
import { useCouponOptions } from './useCouponOptions';
import { FutureStartInput } from './FutureStartInput';

export const SEARCH_COMPONENT_WIDTH = 550;
export const SEARCH_COMPONENT_HEIGHT = 36;

type SubscriptionDiscountConfigurationProps = {
  customer: CustomerResponseFragment;
  currency: Currency;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
};

export function SubscriptionDiscountConfiguration({
  customer,
  currency,
  formRenderProps,
}: SubscriptionDiscountConfigurationProps) {
  const { showCouponFutureStartDate } = useFlags<FeatureFlags>();
  const {
    values: { couponRefId, coupon: existingSubscriptionCoupon },
  } = formRenderProps;
  const [showSearch, setShowSearch] = useState(!!couponRefId);

  const discountFields: Field<SubscriptionFormFields>[] = [
    {
      type: 'custom',
      hide: () => !customer.coupon,
      render: () => (
        <InformationTooltip
          arrow
          placement="top"
          title={<Text.B2>{t('subscriptionForm.discount.customerCouponTooltip')}</Text.B2>}>
          <Box>
            <Text.B2>{formatCoupon(customer.coupon!, currency, { showNegativeSign: false })}</Text.B2>
          </Box>
        </InformationTooltip>
      ),
    },
    {
      id: 'couponRefId',
      hide: () => !!customer.coupon,
      type: 'dynamicDataSearchableSelect',
      gridProps: {
        minWidth: SEARCH_COMPONENT_WIDTH,
      },
      placeholder: t('subscriptionForm.discount.couponPlaceholder'),
      useDataLoader: (search) => useCouponOptions(search, currency, existingSubscriptionCoupon),
      dataTestId: 'select-coupon',
    },
  ];

  const onSetCoupon = () => {
    setShowSearch(true);
  };

  const onUnsetCoupon = ({ setFieldValue, setFieldTouched }: AdditionalInputActionParams) => {
    setShowSearch(false);
    setFieldValue('coupon', undefined, true);
    setFieldValue('couponRefId', undefined, true);
    setFieldValue('couponConfiguration', undefined, true);
    setTimeout(() => setFieldTouched('couponRefId', false, true));
  };

  return (
    <GridFlex.Column gap={2}>
      <Text.H6>{t('subscriptionForm.discount.title')}</Text.H6>
      <AdditionalInputLayout
        hideLabelOnSet
        isSet={!!customer.coupon || showSearch}
        setIcon={{ icon: 'Discount', color: 'default', overrideFill: true, overrideStroke: true }}
        unsetIcon={{ icon: 'Discount', color: 'disabled', overrideFill: true, overrideStroke: true }}
        setLabel={t('subscriptionForm.discount.coupon')}
        buttonLabel={t('sharedComponents.add')}
        fields={discountFields}
        onSet={onSetCoupon}
        onUnset={!customer.coupon ? onUnsetCoupon : undefined}
        formRenderProps={formRenderProps}
      />
      {couponRefId && showCouponFutureStartDate && <FutureStartInput formRenderProps={formRenderProps} />}
    </GridFlex.Column>
  );
}
