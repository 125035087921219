import { CouponFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { CustomDrawer } from '@stigg-components';
import { UpdateCouponForm, UpdateCouponFormFields } from './UpdateCouponForm';

type UpdateCouponDrawerProps = {
  coupon: CouponFragment;
  onUpdate: (values: UpdateCouponFormFields) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function UpdateCouponDrawer({ coupon, onUpdate, open, setOpen }: UpdateCouponDrawerProps) {
  return (
    <CustomDrawer
      title={t('coupons.editDialogTitle')}
      isOpen={open}
      aria-labelledby="edit-coupon-dialog"
      onClose={() => setOpen(false)}
      variant="persistent">
      <UpdateCouponForm onUpdate={onUpdate} onCancel={() => setOpen(false)} coupon={coupon} />
    </CustomDrawer>
  );
}
