import { ErrorCode } from '@stigg-types/apiTypes';
import * as Sentry from '@sentry/react';
import { VariantType } from 'notistack';
import { isEmpty } from 'lodash';
import { enqueueSnackbar } from '../notifications/notificationsSlice';
import { AppDispatch } from '../../redux/store';
import { isStiggError } from './StiggErrorHelper';

const SKIP_SENTRY_ERROR_REPORT: ErrorCode[] = [
  ErrorCode.AccountNotFoundError,
  ErrorCode.AddonNotFound,
  ErrorCode.CouponNotFound,
  ErrorCode.CustomerNotFound,
  ErrorCode.CustomerResourceNotFound,
  ErrorCode.ExperimentNotFoundError,
  ErrorCode.FeatureNotFound,
  ErrorCode.IntegrationNotFound,
  ErrorCode.MemberNotFound,
  ErrorCode.PackageGroupNotFound,
  ErrorCode.PaymentMethodNotFoundError,
  ErrorCode.PlanNotFound,
  ErrorCode.PriceNotFound,
  ErrorCode.ProductNotFoundError,
  ErrorCode.PromotionCodeNotFound,
  ErrorCode.PromotionalEntitlementNotFoundError,
  ErrorCode.SubscriptionNotFound,
];

// allow us to take care of error specific messages
export type FailureMessageHandler = (err: any) => string;

export type ExpectedErrorsHandling = Partial<Record<ErrorCode, string | React.ReactNode>>;

export type SnackbarMessage = { message: string; type: VariantType };

type ExecuteOperationSafelyOptions<T> = {
  successMessage?: string;
  computeSnackbarMessage?: (res: T) => SnackbarMessage[];
  failureMessageHandler?: (err: any) => string;
  expectedErrorsMessage?: ExpectedErrorsHandling;
  autoHideDuration?: number;
  hideErrorMessages?: boolean;
};

function handleStiggError(
  errorCode: string,
  expectedErrors: ExpectedErrorsHandling,
): string | React.ReactNode | undefined {
  const expectedErrorMessage = expectedErrors[errorCode as keyof ExpectedErrorsHandling];
  if (!expectedErrorMessage) {
    console.error(`Got stigg error but no expected message provided: ${errorCode}`);
  }
  return expectedErrorMessage;
}

async function executeOperationSafely<T>(
  operation: () => Promise<T>,
  {
    autoHideDuration,
    computeSnackbarMessage,
    successMessage,
    failureMessageHandler,
    expectedErrorsMessage,
    hideErrorMessages = false,
  }: ExecuteOperationSafelyOptions<T>,
  dispatch: AppDispatch,
) {
  const snackbarOptions = {
    ...(autoHideDuration ? { autoHideDuration } : {}),
  };
  try {
    const res = await operation();

    const messages: SnackbarMessage[] = [];

    if (successMessage) {
      messages.push({ message: successMessage, type: 'success' });
    }

    if (computeSnackbarMessage) {
      messages.push(...computeSnackbarMessage(res));
    }

    if (!isEmpty(messages)) {
      messages.forEach((snackbarMessage) =>
        dispatch(
          enqueueSnackbar({
            message: snackbarMessage.message,
            options: {
              ...snackbarOptions,
              variant: snackbarMessage.type,
            },
          }),
        ),
      );
    }

    return res;
  } catch (err: any) {
    let isHandledError = false;
    let skipSentryReport = false;
    let errorMessage: string | React.ReactNode = 'Oops! Something went wrong!';

    const errorSignInEndpoint = err?.graphQLErrors ? err?.graphQLErrors[0]?.extensions?.signInEndpoint : null;
    if (errorSignInEndpoint) {
      window.location.replace(errorSignInEndpoint);
      // this promise is never resolved, but it's needed to make the function signature correct
      return new Promise<T>(() => {});
    }

    const errorCode = err?.graphQLErrors ? err?.graphQLErrors[0]?.extensions?.code : null;
    if (failureMessageHandler) {
      errorMessage = failureMessageHandler(err);
      if (errorCode && isStiggError(errorCode) && expectedErrorsMessage) {
        const stiggMessage = handleStiggError(errorCode, expectedErrorsMessage);
        if (stiggMessage) {
          errorMessage = stiggMessage;
          isHandledError = true;
        }
      }
    }

    if (SKIP_SENTRY_ERROR_REPORT.includes(errorCode)) {
      skipSentryReport = true;
    }

    if (!hideErrorMessages) {
      dispatch(
        enqueueSnackbar({
          message: errorMessage,
          options: {
            ...snackbarOptions,
            variant: 'error',
          },
        }),
      );
    }
    console.error(err);
    if (!isHandledError && !skipSentryReport) {
      Sentry.captureException(err, { extra: { errorMessage } });
    }
    throw err;
  }
}

export { executeOperationSafely };
