import { t } from 'i18next';
import { ChangeType, DefaultTrialConfigChangeFragment, TrialPeriodUnits } from '@stigg-types/apiTypes';
import { getChange, ChangeRow, ChangeGroupBox } from './DiffBaseComponents';
import { formatTrialSpendLimit } from '../../../pricing/components/SetPriceWizard/form/utils/trialSpendLimitFormat';

function TrialBudgetChangeRow({
  before,
  after,
}: {
  before?: DefaultTrialConfigChangeFragment | null;
  after?: DefaultTrialConfigChangeFragment | null;
}) {
  if (!before?.budget && !after?.budget) {
    return null;
  }

  return (
    <ChangeRow
      before={before?.budget ? formatTrialSpendLimit(before.budget.limit) : null}
      after={after?.budget ? formatTrialSpendLimit(after.budget.limit) : null}
      label={t('pricing.trials.trialSpendLimit')}
      showAlways
      {...getChange(before?.budget, after?.budget)}
    />
  );
}

function TrialEndBehaviorChangeRow({
  before,
  after,
}: {
  before?: DefaultTrialConfigChangeFragment | null;
  after?: DefaultTrialConfigChangeFragment | null;
}) {
  if (!before?.trialEndBehavior && !after?.trialEndBehavior) {
    return null;
  }

  return (
    <ChangeRow
      before={
        before?.trialEndBehavior
          ? t(`subscriptionForm.freeTrial.trialEndBehavior.options.${before.trialEndBehavior}`)
          : null
      }
      after={
        after?.trialEndBehavior
          ? t(`subscriptionForm.freeTrial.trialEndBehavior.options.${after.trialEndBehavior}`)
          : null
      }
      label={t('pricing.trials.whenTrialEnds')}
      showAlways
      {...getChange(before?.trialEndBehavior, after?.trialEndBehavior)}
    />
  );
}

export function DefaultTrialConfigChangeRow({
  before,
  after,
  changeType,
}: {
  before?: DefaultTrialConfigChangeFragment | null;
  after?: DefaultTrialConfigChangeFragment | null;
  changeType?: ChangeType | null;
}) {
  return (
    <ChangeGroupBox changeType={changeType}>
      <ChangeRow
        before={`${before?.duration} ${
          before?.units === TrialPeriodUnits.Day ? t('pricing.trials.days') : t('pricing.trials.months')
        }`}
        after={`${after?.duration} ${
          after?.units === TrialPeriodUnits.Day ? t('pricing.trials.days') : t('pricing.trials.months')
        }`}
        label={t('pricing.trials.trialPeriod')}
        showAlways
        changeType={changeType}
      />
      <TrialBudgetChangeRow before={before} after={after} />
      <TrialEndBehaviorChangeRow before={before} after={after} />
    </ChangeGroupBox>
  );
}
