import { t } from 'i18next';
import React, { useState } from 'react';
import { Box, Button, Collapse, Divider, GridFlex, Icon, IconButton, Text } from '@stigg-components';
import { SubscriptionDataFragment } from '@stigg-types/apiTypes';
import styled from 'styled-components/macro';
import { ChevronDown } from 'react-feather';
import { SubscriptionFutureUpdateRow } from '../../../../subscriptions/subscriptionInfo/components/subscriptionUpdates/SubscriptionFutureUpdateRow';

export const SubscriptionFutureUpdatesCard = styled(Box)<{ $noPaddingTop?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: ${({ theme, $noPaddingTop }) => theme.spacing($noPaddingTop ? 0 : 2)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  background-color: ${({ theme }) => theme.itamar.palette.warning.background};
`;

export const StyledChevronDown = styled(ChevronDown)<{ $isUp: boolean }>`
  color: ${({ theme }) => theme.itamar.palette.warning.content};
  transition: all 0.2s ease-in;
  ${({ $isUp }) =>
    $isUp &&
    `
    transform: rotate(-180deg)
  `}
`;

export function SubscriptionScheduleUpdates({
  subscription,
  setHasSubscriptionUpdatesSkipped,
}: {
  subscription: SubscriptionDataFragment;
  setHasSubscriptionUpdatesSkipped: (hasSubscriptionUpdatesSkipped: boolean) => void;
}) {
  const [isFutureUpdatesOpen, setIsFutureUpdatesOpen] = useState(true);
  const [continuePressed, setContinuePressed] = useState(false);
  const onContinueClick = () => {
    setIsFutureUpdatesOpen(false);
    setContinuePressed(true);
    setHasSubscriptionUpdatesSkipped(true);
  };
  return (
    <SubscriptionFutureUpdatesCard $noPaddingTop={continuePressed}>
      <GridFlex.Column $fullWidth container>
        <GridFlex.RowSpaceBetweenTop container alignItems="center" pl={4}>
          <GridFlex.RowMiddle gap={2}>
            <Icon icon="AlertTriangle" size={22} color="warning" type="reactFeather" />
            <Text.B2 color="warning.content">{t('subscriptions.schedules.alert')}</Text.B2>
          </GridFlex.RowMiddle>
          {continuePressed ? (
            <GridFlex.RowMiddle
              mr={2}
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsFutureUpdatesOpen((prevState) => !prevState)}>
              <Text.B2 color="warning.content">{t('subscriptions.schedules.showChanges')}</Text.B2>
              <IconButton>
                <StyledChevronDown $isUp={isFutureUpdatesOpen} />
              </IconButton>
            </GridFlex.RowMiddle>
          ) : (
            <GridFlex.Item mr={4}>
              <Button color="warning" onClick={onContinueClick} size="medium">
                <Text.B2 color="white">{t('subscriptions.schedules.confirm')}</Text.B2>
              </Button>
            </GridFlex.Item>
          )}
        </GridFlex.RowSpaceBetweenTop>
        {isFutureUpdatesOpen && <Divider mx={4} mt={2} />}
        <Collapse in={isFutureUpdatesOpen}>
          <Box p={4}>
            {subscription.futureUpdates.map((scheduledUpdate, index) => (
              <SubscriptionFutureUpdateRow
                key={index}
                alertVariant
                futureUpdate={scheduledUpdate}
                subscription={subscription}
              />
            ))}
          </Box>
        </Collapse>
      </GridFlex.Column>
    </SubscriptionFutureUpdatesCard>
  );
}
