import { t } from 'i18next';
import React from 'react';
import { IconColor } from '@stigg-theme';
import { EntitlementMethod } from '../modules/customers/components/customerPage/customerEntitlementSummary/components/EntitlementMethod';
import { Icon, InformationalTooltipIcon } from '.';

export const getPolicyTypeIcon = (entitlementMethod?: EntitlementMethod, withStroke = false) => {
  const color: IconColor = withStroke ? 'disabled' : 'active';

  switch (entitlementMethod) {
    case EntitlementMethod.ADDON:
      return (
        <InformationalTooltipIcon icon={<Icon icon="Addon" type="custom" color={color} />} text={t('packages.addon')} />
      );
    case EntitlementMethod.PRICE:
      return (
        <InformationalTooltipIcon
          icon={<Icon icon="DollarSign" color={color} />}
          text={t('pricing.policyTypeTooltip')}
          $maxWidth={240}
        />
      );
    case EntitlementMethod.PLAN:
      return (
        <InformationalTooltipIcon
          icon={<Icon icon="Package" type="custom" color={color} />}
          text={t('packages.plan')}
        />
      );
    case EntitlementMethod.BASE_PLAN:
      return (
        <InformationalTooltipIcon
          icon={<Icon icon="BasePlan" type="custom" color={color} />}
          text={t('customers.summarySectionBasePlan')}
        />
      );
    case EntitlementMethod.OTHER_PRODUCT_PLAN:
      return (
        <InformationalTooltipIcon
          icon={<Icon icon="Grid" color={color} />}
          text={t('customers.summarySectionOtherProductPlan')}
        />
      );
    case EntitlementMethod.PROMOTIONAL:
      return (
        <InformationalTooltipIcon
          icon={<Icon icon="Gift" color={color} />}
          text={t('promotionalEntitlements.policyTypeTooltip')}
        />
      );
    case EntitlementMethod.UNKNOWN:
      return (
        <InformationalTooltipIcon
          icon={<Icon icon="HelpInfo" color={color} />}
          text={t('customers.summaryUnknownSource')}
        />
      );
    default:
      return null;
  }
};
